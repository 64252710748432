import React from "react";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useBookedPropertyFilters } from "../../Utils/bookedPropFilter";
import { FaCaretDown } from "react-icons/fa";
import { FaCaretRight } from "react-icons/fa";
import { ApiBaseUrl } from "../../Utils/urls";
import { initialPropertyFiltersBooked } from "../../Utils/bookedPropFilter";
import { projectionLocations } from "../../Utils/projectionLocation";

const CityButton = ({ city, selectedCities, onClick, isDisabled }) => {
  const isSelected = selectedCities.includes(city);

  return (
    <button
      onClick={() => !isDisabled && onClick(city)} // Only call onClick if not disabled
      disabled={isDisabled}
      className={`cityButton text-black text-sm h-8 px-2 ${
        isSelected ? "bg-blue text-white" : ""
      } ${isDisabled ? "opacity-50 cursor-not-allowed" : ""}`}
    >
      {city}
    </button>
  );
};

const years = [2024, 2025, 2026, 2027, 2028, 2029, 2030];
const YearButton = ({ year, selectedYear, onClick }) => {
  const isSelected = selectedYear === year;

  return (
    <button
      onClick={onClick}
      className={`cityButton text-black text-sm h-8 px-2 ${
        isSelected ? "bg-blue text-white" : ""
      } `}
    >
      {year}
    </button>
  );
};

const quarters = [
  { value: "Jan-Mar", label: "January-March" },
  { value: "Apr-Jun", label: "April-June" },
  { value: "Jul-Sep", label: "July-September" },
  { value: "Oct-Dec", label: "October-December" }
];

const QuarterButton = ({ quarter, selectedQuarter, onClick }) => {
  const isSelected = selectedQuarter === quarter.value;

  return (
    <button
      onClick={onClick}
      className={`cityButton text-black text-sm h-8 px-2 ${
        isSelected ? "bg-blue text-white" : ""
      } `}
    >
      {quarter.label}
    </button>
  );
};

function AssetButton({ name, onClick, assetType }) {
  const isClicked = assetType.includes(name);

  const handleClick = () => {
    onClick(name);
  };
  return (
    <div className="flex flex-row gap-4 w-full" onClick={handleClick}>
      <div className={`checkBox  text-black ${isClicked ? "checked" : ""}`} />
      <button className=" text-black text-sm font-normal">{name}</button>
    </div>
  );
}

function ProjectionFilter(props) {
  const { searchInput, filteredAreas, handleSearchChange } = props;

  const [isFilterActive, setIsFilterActive] = useState(false);
  const [isClearButtonActive, setIsClearButtonActive] = useState(false);

  const [isOpenOptionProp, setIsOpenOptionsProp] = useState(false);
  const [isOpenOptionsSales, setIsOpenOptionsSales] = useState(false);
  const [isOpenOptionsQuarter, setIsOpenOptionsQuarter] = useState(false);
  const [isOpenOptionsYear, setIsOpenOptionsYear] = useState(false);
  const [isOpenOptionsCity, setIsOpenOptionsCity] = useState(false);

  const [isOpenOptionsPrice, setIsOpenOptionsPrice] = useState(false);

  const toggleOptionsProp = () => {
    setIsOpenOptionsProp((prevState) => !prevState);
  };

  const toggleOptionsSales = () => {
    setIsOpenOptionsSales((prevState) => !prevState);
  };
  const toggleOptionsYear = () => {
    setIsOpenOptionsYear((prevState) => !prevState);
  };
  const toggleOptionsQuarter = () => {
    setIsOpenOptionsQuarter((prevState) => !prevState);
  };
  const toggleOptionsCity = () => {
    setIsOpenOptionsCity((prevState) => !prevState);
  };

  const toggleOptionsPriceRange = () => {
    setIsOpenOptionsPrice((prevState) => !prevState);
  };

  const {
    selectedYear,
    setSelectedYear,
    handleLocationSelect,
    selectedQuarter,
    setSelectedQuarter,
    setSelectedCities,
    selectedPriceRange,
    handlePriceRangeChange
  } = props;

  const [isLoggedin, setisLoggedin] = useState(false);
  const [userId, setUserId] = useState(null);
  useEffect(() => {
    const userIdFromLocalStorage = localStorage.getItem("user_id");
    const sessionLSFromLocalStorage = localStorage.getItem("sessionLS");

    if (userIdFromLocalStorage && sessionLSFromLocalStorage) {
      setisLoggedin(true);
      setUserId(userIdFromLocalStorage);
    }
  }, []);

  const [cityClicked, setcityClicked] = useState(false);
  const [BuilderClicked, setBuilderClicked] = useState(false);

  const { BookedpropertyFilters, setBookedPropertyFilters } =
    useBookedPropertyFilters();

  const [clearButtonBeingClicked, setclearButtonBeingClicked] = useState(false);

  const handleClearClick = () => {
    props.setshowMobFilter(!props.showMobFilter);
    setclearButtonBeingClicked(true);
    setTimeout(() => {
      setclearButtonBeingClicked(false);
    }, 200);

    setSelectedCities(["JP Nagar"]);
  };

  return (
    <>
      <div
        className="hideMobFilter flex flex-col  bg-white"
        style={{ height: "100%" }}
      >
        <div className="h-[800px] overflow-y-auto">
          <div className=" text-black pt-4 flex flex-col py-2 px-4">
            <div className="flex flex-row justify-between py-2 mt-10">
              <div
                className="mt-2  text-[16px] font-bold"
                onClick={() => props.setshowMobFilter(false)}
              >
                Filter
              </div>
              <div
                className={`mt-2  text-[14px] font-normal cursor-pointer px-2 py-1 rounded-md ${
                  clearButtonBeingClicked ? "clickedClearDashFilter" : ""
                }`}
                onClick={handleClearClick}
              >
                Clear All
              </div>
            </div>
          </div>

          <div className=" text-black flex flex-col px-4 py-2">
            <div
              className="flex flex-row justify-between"
              onClick={toggleOptionsYear}
            >
              <div className="text-base font-medium text-start">
                Select Year
              </div>

              <div className="flex flex-col justify-center">
                {isOpenOptionsYear ? <FaCaretRight /> : <FaCaretDown />}
              </div>
            </div>

            {isOpenOptionsYear && (
              <div className=" flex flex-row flex-wrap gap-2 p-2 -ml-2">
                {years.map((year) => (
                  <YearButton
                    key={year}
                    year={year}
                    selectedYear={selectedYear}
                    onClick={() => setSelectedYear(year)}
                  />
                ))}
              </div>
            )}
          </div>

          {/* <div className='flex flex-row justify-center mt-1'>
    <hr style={{ color: "rgba(217, 217, 217, 1)",width:"100%" }}></hr>
  </div> */}

          {/* <div className='flex flex-col justify-between px-4 py-2'>
    
    <div className='flex flex-row justify-between' onClick={toggleOptionsProp}>
        <div className='text-base font-medium text-start'>Property Phases</div>
        
        <div className='flex flex-col justify-center'>
         {isOpenOptionProp ? <FaCaretRight /> : <FaCaretDown />}
        </div>
    </div>

    <div>
    {isOpenOptionProp && (
      <div className='openOptions'>
        <div className='flex flex-row flex-wrap gap-2 p-2 -ml-2'>
          <button
            className={`propertyPhasesButton text-black text-sm w-32 h-8 ${
              props.propertyPhases.includes('launching') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
            }`}
            onClick={() => handlePropertyPhaseClick('launching')}
          >
            Launching Soon
          </button> 
          <button
            className={`propertyPhasesButton text-black text-sm w-16 h-8 ${
              props.propertyPhases.includes('open') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
            }`}
            onClick={() => handlePropertyPhaseClick('open')}
          >
            Open
          </button>
          <button
            className={`propertyPhasesButton text-black text-sm w-16 h-8 ${
              props.propertyPhases.includes('resale') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
            }`}
            onClick={() => handlePropertyPhaseClick('resale')}
          >
            Resale
          </button>
          <button
          className={`propertyPhasesButton text-black text-sm w-20 h-8 ${
            props.propertyPhases.includes('soldout') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
          }`}
          onClick={() => handlePropertyPhaseClick('soldout')}
        >
          Sold Out
        </button>
        </div>
      </div>
    )}
    </div>
  </div> */}

          <div className="flex flex-row justify-center">
            <hr style={{ color: "rgba(217, 217, 217, 1)", width: "100%" }}></hr>
          </div>

          <div className=" text-black flex flex-col px-4 py-2">
            <div
              className="flex flex-row justify-between"
              onClick={toggleOptionsSales}
            >
              <div className="text-base font-medium text-start">Asset Type</div>

              <div className="flex flex-col justify-center">
                {isOpenOptionsSales ? <FaCaretRight /> : <FaCaretDown />}
              </div>
            </div>

            {isOpenOptionsSales && (
              <div className=" flex flex-row flex-wrap gap-2 p-2 -ml-2">
                <button
                  className={`propertyPhasesButton  text-black text-sm w-24 h-8 ${
                    props.saleTypes.includes("Residential")
                      ? "propertyCollectButton"
                      : "propertyCollectButtonBefore"
                  }`}
                >
                  Residential
                </button>

                {/* <button
        className={`propertyPhasesButton  text-black text-sm w-24 h-8  whitespace-nowrap ${
          props.saleTypes.includes('Commercial') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSaleTypeClick('Commercial')}
      >
        Commercial
      </button>

      <button
        className={`propertyPhasesButton  text-black text-sm w-24 h-8 whitespace-nowrap ${
          props.saleTypes.includes('Plots') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSaleTypeClick('Plots')}
      >
        Plots
      </button> */}
              </div>
            )}
          </div>

          <div className="flex flex-row justify-center">
            <hr style={{ color: "rgba(217, 217, 217, 1)", width: "100%" }}></hr>
          </div>

          <div
            className=" text-black flex flex-col py-2 px-4 "
            onClick={() => setcityClicked(true)}
          >
            <div
              className="flex flex-row justify-between"
              onClick={toggleOptionsCity}
            >
              <div className="text-base font-medium text-start">
                Select City
              </div>

              <div className="flex flex-col justify-center">
                {isOpenOptionsCity ? <FaCaretRight /> : <FaCaretDown />}
              </div>
            </div>

            {isOpenOptionsCity && (
              <div className=" flex flex-col gap-2 p-2 -ml-2 h-64 overflow-y-auto">
                <select
                  name=""
                  value={props.mainCity}
                  className="px-[2px] py-[4px] rounded-md"
                  style={{ border: "1px solid #CCCCCC" }}
                  onChange={(e) => props.setMainCity(e.target.value)}
                  disabled
                >
                  <option value="" disabled>
                    Select City
                  </option>
                  <option value="Bangalore">Bengaluru</option>
                  {/* <option value="Mumbai">Mumbai</option> */}
                </select>

                <input
                  type="text"
                  className="border border-lightgray px-1"
                  placeholder="Search area...."
                  value={searchInput}
                  onChange={handleSearchChange}
                />

                {filteredAreas.length > 0 ? (
                  filteredAreas.map((el) => (
                    <div className=" flex flex-row text-start">
                      <input
                        type="checkbox"
                        checked={props.selectedCities.includes(el)}
                        onChange={() => handleLocationSelect(el)}
                        disabled={
                          props.selectedCities.length >= 5 &&
                          !props.selectedCities.includes(el)
                        }
                      />
                      <span className="ml-2">{el}</span>

                      <div className="flex flex-col justify-center"></div>
                    </div>
                  ))
                ) : (
                  <div>No results found</div>
                )}
              </div>
            )}
          </div>

          <div className="flex flex-row justify-center">
            <hr style={{ color: "rgba(217, 217, 217, 1)", width: "100%" }}></hr>
          </div>

          <div className=" text-black flex flex-col px-4 py-2">
            <div
              className="flex flex-row justify-between"
              onClick={toggleOptionsQuarter}
            >
              <div className="text-base font-medium text-start">
                Select Quarter
              </div>

              <div className="flex flex-col justify-center">
                {isOpenOptionsQuarter ? <FaCaretRight /> : <FaCaretDown />}
              </div>
            </div>

            {isOpenOptionsQuarter && (
              <div className=" flex flex-row flex-wrap gap-2 p-2 -ml-2">
                {quarters.map((quarter) => (
                  <QuarterButton
                    key={quarter.value}
                    quarter={quarter}
                    selectedQuarter={selectedQuarter}
                    onClick={() => setSelectedQuarter(quarter.value)}
                  />
                ))}
              </div>
            )}
          </div>

          <div className="flex flex-row justify-center">
            <hr style={{ color: "rgba(217, 217, 217, 1)", width: "100%" }}></hr>
          </div>

          <div className=" text-black flex flex-col px-4 py-2">
            <div
              className="flex flex-row justify-between"
              onClick={toggleOptionsPriceRange}
            >
              <div className="text-base font-medium text-start">
                Price Range
              </div>

              <div className="flex flex-col justify-center">
                {isOpenOptionsPrice ? <FaCaretRight /> : <FaCaretDown />}
              </div>
            </div>

            {isOpenOptionsPrice && (
              <div className=" flex flex-row flex-wrap gap-2 p-2 -ml-2">
                <div className=" flex flex-row gap-2 ">
                  <input
                    type="radio"
                    name="price"
                    id="minPrice"
                    value="min"
                    checked={selectedPriceRange === "min"}
                    onChange={handlePriceRangeChange}
                  />
                  <label
                    htmlFor="minPrice"
                    className={`flex flex-col justify-center ${
                      selectedPriceRange === "min" ? "selected" : ""
                    }`}
                  >
                    Minimum Price
                  </label>
                </div>

                <div className=" flex flex-row gap-2">
                  <input
                    type="radio"
                    name="price"
                    id="avgPrice"
                    value="avg"
                    checked={selectedPriceRange === "avg"}
                    onChange={handlePriceRangeChange}
                  />
                  <label
                    htmlFor="avgPrice"
                    className={`flex flex-col justify-center ${
                      selectedPriceRange === "avg" ? "selected" : ""
                    }`}
                  >
                    Average Price
                  </label>
                </div>

                <div className=" flex flex-row gap-2">
                  <input
                    type="radio"
                    name="price"
                    id="maxPrice"
                    value="max"
                    checked={selectedPriceRange === "max"}
                    onChange={handlePriceRangeChange}
                  />
                  <label
                    htmlFor="maxPrice"
                    className={`flex flex-col justify-center ${
                      selectedPriceRange === "max" ? "selected" : ""
                    }`}
                  >
                    Maximum Price
                  </label>
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-row justify-center mt-2">
            <hr style={{ color: "rgba(217, 217, 217, 1)", width: "100%" }}></hr>
          </div>
        </div>
        <div className="flex flex-row w-full justify-between absolute bottom-0 py-2 px-4 mt-10 bg-white">
          <div
            className="flex flex-col justify-center mt-2  text-[14px] font-normal belowBorders h-[44px] w-[150px] rounded-lg cursor-pointer"
            onClick={() => props.setshowMobFilter(false)}
          >
            Close
          </div>
          <div
            className="flex flex-col justify-center mt-2  text-[14px] text-white font-normal belowBorders h-[44px] w-[150px] rounded-lg bg-secondary cursor-pointer"
            onClick={() => props.setshowMobFilter(false)}
          >
            Apply
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectionFilter;
