import React, { useEffect, useState } from "react";
import "./advisor-style.css";
import ReactSlider from "react-slider";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartSection from "./ChartSection";
import GoogleMapComp from "./GoogleMapComp";
import useDebounce from "../../Utils/Debounce";
import { fetchPrdictedRoi } from "../../Api/PredictionApis";
ChartJS.register(ArcElement, Tooltip, Legend);

export default function SmartAdvisorCompo() {
  const [investmentAmount, setinvestmentAmount] = useState(5000);
  const [timePeriod, setTimePeriod] = useState(2);
  const [selectedRiskCapacity, setSelectedRiskCapacity] = useState("Moderate");
  const [estRoi, setEstRoi] = useState(0);
  const [totalReturn, setTotalReturn] = useState(0);
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [locality, setLocality] = useState("");
  const [isMinValErr, setMinValErr] = useState(false);

  const handleRiskCapacityChange = (e) => {
    setSelectedRiskCapacity(e.target.value);
  };

  const chartdata = {
    labels: [
      `Total Returns (${Number(totalReturn).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })})`,
      `Amount to Invest (${investmentAmount < 5000 ? 5000 : investmentAmount})`
    ],
    datasets: [
      {
        //label: 'My First Dataset',
        data: [totalReturn, investmentAmount],
        backgroundColor: ["#D328A7", "#D9D9D9"],
        hoverOffset: 4
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top"
      },
      tooltip: {
        enabled: true
      }
    }
  };

  // console.log(investmentAmount,timePeriod,selectedRiskCapacity);

  const getPrdictedRoi = () => {
    fetchPrdictedRoi(
      investmentAmount,
      selectedRiskCapacity,
      timePeriod,
      setEstRoi,
      setTotalReturn,
      setLat,
      setLong,
      setLocality
      // setMinValErr
    );
  };
  const debounce = useDebounce();
  const debouncedFetchROI = debounce(getPrdictedRoi, 500);

  useEffect(() => {
    debouncedFetchROI();
  }, [investmentAmount, timePeriod, selectedRiskCapacity]);

  return (
    <div className="">
      <div className="text-center w-full   px-1 sm:px-2.5  pb-4">
        <p className="text-2xl sm:text-base font-medium text-center sm:text-left">
          Smart Advisor
        </p>
      </div>

      <div className="profileSection">
        <div className="innerPart">
          <div className="topPart tab-shadow flex flex-col gap-5">
            <p className="text-lg font-semibold text-blue text-start">
              A smarter approach to investment advice
            </p>

            <div className="flex md:flex-row flex-col  gap-5 w-full">
              {/* left Section */}
              <div className="flex flex-col  md:w-[45%] gap-4 p-2.5 border border-lightgray rounded-lg">
                <p className="text-xs font-bold text-left">Investment Inputs</p>

                <div className="flex flex-col gap-5   divide-y divide-lightgray ">
                  <div className="flex flex-col gap-5 pb-9 pt-4">
                    <div className="flex justify-between items-center">
                      <p className="text-xs font-bold ">Amount to Invest</p>

                      <input
                        id="investment"
                        type="number"
                        value={investmentAmount}
                        min={5000}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setinvestmentAmount(
                            inputValue === "" ? "" : parseInt(inputValue, 10)
                          );
                          if (inputValue === "" || inputValue < 5000) {
                            setMinValErr(true);
                            //setinvestmentAmount(5000);
                          } else {
                            setMinValErr(false);
                          }

                          // onBlur={() => {
                          //   if (
                          //     investmentAmount === "" ||
                          //     investmentAmount < 5000
                          //   ) {
                          //     setMinValErr(true);
                          //     setinvestmentAmount(5000);
                          //   } else {
                          //     setMinValErr(false);
                          //   }
                          // }}
                        }}
                        className={`bg-lightgray rounded-md px-2 ${
                          isMinValErr ? "border border-red" : ""
                        }`}
                      />
                    </div>

                    <p className="text-xs text-red ">
                      {isMinValErr ? "Minimun allowed value is 5000" : ""}
                    </p>
                    <ReactSlider
                      value={investmentAmount}
                      onChange={(value) => {
                        if (value === "" || value < 5000) {
                          setMinValErr(true);
                        } else {
                          setMinValErr(false);
                          setinvestmentAmount(value);
                        }
                      }}
                      className="custom-slider"
                      thumbClassName="thumb"
                      markClassName="example-mark"
                      renderTrack={(props, state) => (
                        <div
                          {...props}
                          className={`track ${
                            state.index === 0
                              ? "track-selected"
                              : "track-unselected"
                          }`}
                        />
                      )}
                      renderThumb={(props, state) => (
                        <div {...props}>
                          <div className="tooltip-advisor">
                            {state.valueNow}
                          </div>
                        </div>
                      )}
                      min={5000}
                      max={1000000}
                    />
                  </div>

                  <div className="flex flex-col gap-5 pt-4">
                    <p className="text-xs font-bold text-left">Risk Capacity</p>

                    <div className="flex gap-7">
                      <div className="flex flex-col gap-2.5 justify-between">
                        <input
                          type="radio"
                          value={"Low"}
                          onChange={handleRiskCapacityChange}
                          checked={selectedRiskCapacity === "Low"}
                        />
                        <label>Low</label>
                      </div>
                      <div className="flex flex-col gap-2.5 justify-between">
                        <input
                          type="radio"
                          value={"Moderate"}
                          onChange={handleRiskCapacityChange}
                          checked={selectedRiskCapacity === "Moderate"}
                        />

                        <label>Moderate</label>
                      </div>
                      <div className="flex flex-col gap-2.5 justify-between">
                        <input
                          type="radio"
                          value={"High"}
                          onChange={handleRiskCapacityChange}
                          checked={selectedRiskCapacity === "High"}
                        />
                        <label>High</label>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-5 pb-12 pt-4">
                    <div className="flex justify-between items-center">
                      <p className="text-xs font-bold ">
                        Time Period <span className="text-[10px]">(Years)</span>{" "}
                      </p>
                      <input
                        type="number"
                        min={1}
                        max="5"
                        className="bg-lightgray rounded-md px-2"
                        value={timePeriod}
                        onChange={(e) => setTimePeriod(e.target.value)}
                        disabled
                      />
                    </div>
                    <ReactSlider
                      value={timePeriod}
                      onChange={(val) => setTimePeriod(val)}
                      className="custom-slider"
                      thumbClassName="thumb"
                      markClassName="example-mark"
                      renderTrack={(props, state) => (
                        <div
                          {...props}
                          className={`track ${
                            state.index === 0
                              ? "track-selected"
                              : "track-unselected"
                          }`}
                        />
                      )}
                      renderThumb={(props, state) => (
                        <div {...props}>
                          <div className="tooltip-advisor">
                            {state.valueNow}
                          </div>
                        </div>
                      )}
                      min={1}
                      max={5}
                    />
                  </div>
                </div>
              </div>

              {/* right section */}
              <div className="flex flex-col gap-5 md:w-[55%]">
                <div className="flex flex-col   gap-4 p-2.5 border border-lightgray rounded-lg">
                  <p className="text-lg font-semibold text-blue text-start">
                    Investment Returns Overview
                  </p>

                  <ChartSection
                    data={chartdata}
                    options={options}
                    investmentAmount={investmentAmount}
                    timePeriod={timePeriod}
                    estRoi={estRoi}
                    totalReturn={totalReturn}
                  />
                </div>

                <div className="flex flex-col gap-4 p-2.5 border border-lightgray rounded-lg">
                  <p className="text-lg font-semibold text-blue text-start">
                    Best locality to invest in: {locality && locality}
                  </p>
                  <GoogleMapComp lat={lat} long={long} locality={locality} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
