import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { GOOGlE_MAP_KEY } from "../../Utils/urls";

const GoogleMapComp = ({ lat, long, locality }) => {
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGlE_MAP_KEY
  });

  useEffect(() => {
    setMapCenter({ lat, lng: long });
  }, [lat, long]);

  if (!isLoaded) {
    return <div>Loading map...</div>;
  }

  return (
    <div style={{ height: "200px", width: "100%" }}>
      <GoogleMap
        center={mapCenter}
        zoom={15}
        mapContainerStyle={{ height: "100%", width: "100%" }}
      >
        <Marker
          position={mapCenter}
          label={{
            text: locality,
            color: "white",
            fontSize: "15px"
          }}
        />
      </GoogleMap>
    </div>
  );
};

export default GoogleMapComp;
