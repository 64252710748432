import React, { useEffect, useRef, useState } from 'react'
import SharePopup from '../SharePopup';
import { ApiBaseUrl } from '../../../Utils/urls';
import "../../PropertyDetails/index.css"
import nopurchase from "../../../Assets/icons/no-transaction.png"
import CommonAccordianTab from '../CommonAccordianTab';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { BiUpArrowAlt } from 'react-icons/bi';
import { MdOutlineShare } from 'react-icons/md';
import { FaRegHeart } from 'react-icons/fa';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FaHeart } from 'react-icons/fa6';
import InfoPopup from '../../InfoPopup/InfoPopup';
import { getUserDetails } from '../../../Api/ProfileApi';
import SuccessAlert from '../../Alert/SuccessAlert';
import { ClickOutside, DecrementToken, IncrementToken, ProceedToPurchase, calculateTotalPrice } from '../../../Utils/tokenCalculation';
import StickyPriceComponent from './StickyPriceComponent';
import { getPropertyDetails } from '../../../Api/PropertyApi';
import { purchaseformatDate } from '../../../Utils/purchaseHistDateFormat';





export default function GrowthTabMobile() {
    const location=useLocation()
  const [details,setPropertyDetails]=useState({})
    const {property_info,invoice_info,purchase_history}=details

    const [tokenCount, setTokenCount] = useState(1); 
    const [showSharePopup,setSharePopup]=useState(false)
    const [showStickyPriceSection,setshowStickyPriceSection]=useState(false)
    const [formattedPrice, setFormattedPrice] = useState(0);
    const [platformFee,setPlatformFee]=useState(1)
    const [gstFee,setGstFee]=useState(1)
    const initialTotalFee=parseFloat(property_info?.price_per_share) + platformFee + gstFee
    const [totalFee,setTotalFee]=useState(initialTotalFee)
    const [reachedTokenLimit, setReachedTokenLimit] = useState(false);
    const tokenLimit=details.property_info?.limit_per_share

    const [isLoggedIn,setLoggedin]=useState(false)
    const [kycStatus,setKycStatus]=useState("")

    const userId=localStorage.getItem("user_id")
    const accestoken=localStorage.getItem("accestoken")

    const [isUserEmail,setUserEmail]=useState("")
    const [isEmailUpdated,setEmailupdated]=useState(true)
    const [isPropComingSoon,setPropComingSoon]=useState("")

    const propertyName=property_info?.property_name
    const propertyCity=property_info?.property_city

    const fetchUserDetails=()=>{
        getUserDetails(userId,accestoken,setLoggedin,setKycStatus,setUserEmail,navigate)
 }
        
        useEffect(()=>{
            if(userId)
                {
                    fetchUserDetails()
                }
        },[])
        


   const navigate=useNavigate()
    const {id}=useParams()
    
    const [showYieldInfoPopup, setShowYieldInfoPopup] = useState(false);
    const [showIRRInfoPopup, setShowIRRInfoPopup] = useState(false);
    const [showCapitalInfoPopup, setShowCapitalInfoPopup] = useState(false);

  const handleshowYieldInfoPopup = () => {
    setShowYieldInfoPopup(true);
  };
  const handleshowIRRInfoPopup = () => {
    setShowIRRInfoPopup(true);
  };
  const handleshowCapitalInfoPopup = () => {
    setShowCapitalInfoPopup(true);
  };

  const handlecloseYieldInfoPopup = () => {
    setShowYieldInfoPopup(false);
  };
  const handlecloseIRRInfoPopup = () => {
    setShowIRRInfoPopup(false);
  };
  const handlecloseCapitalInfoPopup = () => {
    setShowCapitalInfoPopup(false);
  };

  
//make this section reusable

//ectract the element with property invoice_details
let invoiceDetails=invoice_info?.find(el=>el?.hasOwnProperty("invoice_details"))
const PerTokenPrice = property_info?.price_per_share ;
//get the array
invoiceDetails=invoiceDetails?.invoice_details

//get the element with platformm handling fee name as an object
let phmArray=invoiceDetails?.find(el=>el.invoice_name.toLowerCase()==="Platform Handling fee".toLowerCase())
const phm_percentage=phmArray?.invoice_price


let gstArray=invoiceDetails?.find(el=>el.invoice_name.toLowerCase()==="gst".toLowerCase())
const gst_percentage=gstArray?.invoice_price

//make this section reusable end

    const handleIncrement = () => {
        IncrementToken(tokenCount, setTokenCount, tokenLimit, setReachedTokenLimit);
    };

   
    const handleDecrement = () => {
        DecrementToken(tokenCount, setTokenCount, setReachedTokenLimit);
    };

    //toggle show share popup
    const handleToggleSharePopup=()=>{
        setSharePopup(!showSharePopup)
    }

    const price = property_info?.price_per_share ;

useEffect(() => {
    const { totalPrice, calculatedPlatformFee, gst_fee, total_fee } = calculateTotalPrice(PerTokenPrice, tokenCount, phm_percentage, gst_percentage);

    setFormattedPrice(totalPrice.toLocaleString());
    setPlatformFee(calculatedPlatformFee);
    setGstFee(gst_fee);
    setTotalFee(total_fee);
}, [tokenCount, PerTokenPrice, phm_percentage, gst_percentage]);




const totalPrice = price * tokenCount;

const handleProceed = () => {
ProceedToPurchase(
    isLoggedIn,
    kycStatus,
    isUserEmail,
    navigate,
    tokenCount,
    totalPrice,
    formattedPrice,
    property_info,
    gstFee,
    platformFee,
    totalFee,
    id,
    PerTokenPrice,
    setEmailupdated,
    location,
 propertyName,
    propertyCity
)

    }
    


const handleToggleStickyPriceSection=()=>{
    setshowStickyPriceSection(!showStickyPriceSection)
}


const priceSectionRef = useRef(null);

    useEffect(() => {
        // Add event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Remove event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // clicks outside the price section close it
    const handleClickOutside = (event) => {
      ClickOutside(event,priceSectionRef,setshowStickyPriceSection)
    };

    const handleClosePushSection=()=>{
        setshowStickyPriceSection(false)
    }




const user_id=localStorage.getItem("user_id")
let  propertyDetailsURl=""
if(user_id)
{
  propertyDetailsURl=`${ApiBaseUrl}/fr-property/fetch-property-details?property_id=${id}&user_id=${user_id}`

}
else{
  propertyDetailsURl=`${ApiBaseUrl}/fr-property/fetch-property-details?property_id=${id}`
 
}

const fetchPropertyDetails = async () => {
    getPropertyDetails(propertyDetailsURl,setPropertyDetails,setPropComingSoon)
 };

useEffect(() => {


  fetchPropertyDetails();
}, [id]);

  

const addToWishlist = () => {
    const userId = localStorage.getItem("user_id");
    if (userId) {
        // Make API request to add property to wishlist
        fetch(`${ApiBaseUrl}/fr-user/wishlist-property`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${localStorage.getItem("accestoken")}`
            },
            body: JSON.stringify({
                "user_id": userId,
                "property_id": id
            })
        })
        .then(response => response.json())
        .then(data => {
           
            if (data.success) {
                
                
               fetchPropertyDetails()
            }

            // Handle success or error message as required
        })
        .catch(error => console.error('Error:', error));
    } else {
        // Redirect to login page if user is not logged in
        navigate('/login');
    }
};



  return (


    <>

{
    !isEmailUpdated && (
        <SuccessAlert message="Please Update Your Email"/>
    )
}
    
    <div className="sticky-button">
        <button onClick={handleToggleStickyPriceSection} className='w-full bg-secondary text-white py-2.5 px-4 rounded-md font-semibold text-base'>
            Select Token
        </button>
    </div>


{
    showStickyPriceSection && 
    (

<StickyPriceComponent
formattedPrice={formattedPrice} 
gstFee={gstFee}
handleClosePushSection={handleClosePushSection}
handleDecrement={handleDecrement}
handleIncrement={handleIncrement}
handleProceed={handleProceed}
platformFee={platformFee}
price={price}
priceSectionRef={priceSectionRef}
reachedTokenLimit={reachedTokenLimit}
showStickyPriceSection={showStickyPriceSection}
tokenCount={tokenCount}
tokenLimit={tokenLimit}
totalFee={totalFee}
isPropComingSoon={isPropComingSoon}
/>


    )
}
    



   <div className='relative'>



<div className='absolute presale-badge-mobile'>{property_info?.property_phase}</div>
    <div className='detailsCard-mobile tab-shadow'>



{/* Badge-section */}
<div className='badgeSection flex flex-row-reverse '>

   

    <div className='flex gap-5'>

    <MdOutlineShare style={{fontSize:'18px',color:"gray",cursor:"pointer"}} onClick={handleToggleSharePopup} />
    {
    property_info?.is_wishlisted==0?
    <FaRegHeart onClick={addToWishlist} className='cursor-pointer' style={{fontSize:'20px',color:"gray"}} />
    :
    <FaHeart onClick={addToWishlist} className='cursor-pointer' style={{fontSize:'20px',color:"red"}}/>
    
}
    </div>

{showSharePopup && <SharePopup/> }
</div>






<div className="investmentSection flex justify-between rounded-lg my-5 py-2.5 px-4">

<div className='flex flex-col items-center'>
    <p  className='font-semibold font-nunito text-gray  text-sm'>
    
    {property_info?.property_phase==="Presale"?"Est. Yield":"Yield"}
    </p>

    <div className="flex items-center gap-1">
    <div  className='font-semibold text-blue  text-base'>
    {property_info?.gross_yield}%</div>
    

         

{/* web-info */}

<IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} onClick={handleshowYieldInfoPopup} />

{

    showYieldInfoPopup && (
        <>
         
                        <InfoPopup>
                            <div className='p-2'>
                            <p className='text-blue text-left text-sm'>What is {property_info?.property_phase==="Presale"?"Est. Yield":"Yield"}?</p>
                                <p className='text-gray text-left text-sm'>

                                    {property_info?.property_phase==="Presale"?
                                    "It represents the percentage of annual rental income expected from the property.":
                                    "It represents the percentage of annual rental income generated from the property."
                                    
                                    }
                                   
                            </p>
                            </div>
           
                            <button onClick={handlecloseYieldInfoPopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                Ok</button>
                            
                            </InfoPopup>
        </>
    )
}
                        
   
    

    </div>
    
</div>




<div className='flex flex-col items-center'>
    <p  className='font-semibold text-gray font-nunito  text-sm'>
    {property_info?.property_phase==="Presale"?"Target ARR":"Target IRR"}
    </p>

    <div className="flex items-center gap-1">
        <div  className='font-semibold text-blue  text-base'>
        {property_info?.target_IRR}%
        </div>
      
  <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} onClick={handleshowIRRInfoPopup} />

{

    showIRRInfoPopup && (
        <>
         
                        <InfoPopup>
                            <div className='p-2'>
                            <p className='text-blue text-left text-sm'>What is {property_info?.property_phase==="Presale"?"Target ARR":"Target IRR"}?</p>
                                <p className='text-gray text-left text-sm'>
                                {property_info?.property_phase==="Presale"?
                            "Target ARR refers to desired increase in the value of Investment on an annual basis.":
                            "	It is the return rate investors aim to achieve from an investment, considering its projected cash flows."
                            }
                            </p>
                           


                            </div>
           
                            <button onClick={handlecloseIRRInfoPopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                Ok</button>
                            
                            </InfoPopup>
        </>
    )
}
    </div>
    
</div>




<div className='flex flex-col items-center'>
    <p  className='font-semibold text-gray font-nunito text-sm'>
    Potential Gain
          {/* {property_info?.sale_type==="Presale"?"Potential Gain":"Capital Gain"} */}
    </p>

    <div className="flex items-center gap-1">
   
   <BiUpArrowAlt style={{fontSize:"23px",color: "#2FDF99"}} />
       <div  className='font-semibold text-blue  text-base'>
          
       {property_info?.capital_gain}%
       </div>
       

   

    <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} onClick={handleshowCapitalInfoPopup} />

{

    showCapitalInfoPopup && (
        <>
         
                        <InfoPopup>
                            <div className='p-2'>
                            <p className='text-blue text-left text-sm'>What is Potential Gain?
                            
                             {/* {property_info?.sale_type==="Presale"?"Potential Gain":"Capital Gain"} */}
                             </p>
                                <p className='text-gray text-left text-sm'> 
                                It refers to the estimated gain generated upon sale of the property.

                                {/* {property_info?.sale_type==="Presale"?
                            "It refers to the estimated gain generated upon sale of the property.":
                            "	It refers to the estimated profit generated upon sale of the property."
                            } */}
                            </p>
                            </div>
           
                            <button onClick={handlecloseCapitalInfoPopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                Ok</button>
                            
                            </InfoPopup>
        </>
    )
}

   </div>
    
</div>






</div>



{/* token-section  */}
<div className="tokenSection flex flex-col gap-2.5 text-sm">
<div className="flex justify-between ">
    <p className='font-nunito font-normal text-gray '>Available Tokens</p>
    <p className='font-nunito font-bold text-blue'>
    {property_info?.total_shares-(Number(property_info?.reserved_tokens)+ property_info?.sold_shares)}</p>
</div>
<div className="flex justify-between">
    <p className='font-nunito font-normal text-gray' >Total Tokens</p>
    <p className='font-nunito font-bold text-blue'>{property_info?.total_shares}</p>
</div>

<div className="flex justify-between">
    <p className='font-nunito font-normal text-gray' >Lock in Period</p>
    <p className='font-nunito font-bold text-blue'>{property_info?.lock_in_period} days</p>
</div>
</div>

{/********************* tokensold-progressbar-section *************/}
<div className='text-black flex flex-col  mt-2 p-1 gap-1'>
                <div className='flex flex-row justify-center'>
                <div className='tokenSoldProgressbar text-black flex flex-row'>

                   

                    <div className='flex progressLength flex-row-reverse' style={{ width: `${(((property_info?.sold_shares) / property_info?.total_shares) * 100)}%` }}>
              <div className='tokenIcon bg-navyBlue text-white  flex flex-col justify-center'>
                   <div className='fontTokenPB font-bold bg-black rounded-xl w-[13px]' style={{marginLeft:'15px'}} >T</div>
               </div>
                </div>


                </div>
                </div>

                <div className='text-black text-sm font-nunito'>
                    {<span className='text-secondary font-extrabold'>{property_info?.sold_shares||0} </span>} 
                 out of {<span className='text-secondary font-extrabold'>{property_info?.total_shares} </span>} 
                tokens invested</div>
            </div>
{/********************* tokensold-progressbar-section-end *************/}



{/* price-section */}
<div className="priceSection">
   
    <hr className='' style={{backgroundColor:"#D9D9D9",border:0,height:"1px"}} />

   
       </div>



    </div>

    <div className="mt-5 ">

    <CommonAccordianTab title="Purchase History" >





<div className='flex flex-col purchaseSec'>
<div className='flex w-full py-2 border font-semibold text-blue border-b-lightgray border-t-0 border-r-0 border-l-0'>

  
    <div className='phSlNo  '>SNo.</div>
    <div className='phOwnerId '>Owner ID</div>
    <div className='phTime '>Purchase Time</div>
    <div className='phToken '>No. Of Tokens</div>
    <div className='phPrice'><p>Price</p></div>

</div>



{
  purchase_history && purchase_history.length==0
  ?
  

 

  (
    
  
     <div className='flex flex-col justify-center items-center text-gray py-5'>
       <img src={nopurchase} alt="no purchase history" className='w-28' />
       <p >No purchase history available </p>
       <p>at the moment.</p>
     </div>
   


 
 )
:
(
  purchase_history && purchase_history.map((el,index)=>(
    

<div className='flex w-full py-2 border border-b-lightgray border-t-0 border-r-0 border-l-0'>

  
<div className='phSlNo'>{index+1}</div>
<div className='phOwnerId'>{el && el.user_id}</div>
<div className='phTime'>{purchaseformatDate(el && el.transaction_date)}</div>
<div className='phToken'>{el && el.number_of_shares}</div>
<div className='phPrice'><p>₹ {el && el.price.toLocaleString()}</p></div>

</div>



  ))





)


}



</div>





</CommonAccordianTab>
    </div>



    </div>
    </>
  )
}
