import React, { useState, useEffect,useMemo,useRef } from 'react';
import './Index.css';
import DashboardSideNav from '../DashboardSideNav/Index';
import Navbar from '../Navbar/Index'
import DashboardList from '../../Assets/Dashboard/dashboardList.svg'
//import DashboardList from '../../Assets/Dashboard/dashboard_top.png'
import lock from '../../Assets/Dashboard/lockDashboard.svg'

import Dashboard5 from '../../Assets/Dashboard/dashboard5.svg'
import Dashboard6 from '../../Assets/Dashboard/dashboard6.svg'
import Dashboard7 from '../../Assets/Dashboard/dashboard7.svg'
import totalinvest from "../../Assets/Dashboard/Total_Investment.png"
import propertyHolding from "../../Assets/Dashboard/Properties_Holding.png"
import currentValue from "../../Assets/Dashboard/Current_Value.png"
import totalReturn from "../../Assets/Dashboard/Total_Returns.png"
import avgIRR from "../../Assets/Dashboard/Average_IRR.png"
import avgYield from "../../Assets/Dashboard/Average_Rental_Yield.png"
import AvailableProperties from '../AvailableProperties/AvailableProp';
import Info from '../../Assets/Dashboard/info.svg'
import { IoMdInformationCircleOutline, IoMdSearch } from "react-icons/io";

import ProfilePage from '../Profile/ProfilePage';
import DashboardMobile from '../DashboardMobile/Index';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ApiBaseUrl } from '../../Utils/urls';
import {handlelogout} from '../../Utils/logout'
import { BeatLoader } from 'react-spinners'
import BookedProperties from '../BookedProperties/Index';
import MobFilter from '../MobileFilter/Index';
import { PropertyFiltersProvider1 } from '../../Utils/bookedPropFilter';
import WarningAlert from '../Alert/WarningAlert';
import { useAuth } from '../useAuth/useAuth';
import ResalePropWeb from '../ResaleDashboard/ResalePropWeb/ResalePropWeb';
import SoldoutPropWeb from '../SoldoutDashboard/SoldoutPropWeb/SoldoutPropWeb';





function Dashboard() {
const [leftPaneHeight, setLeftPaneHeight] = useState(window.innerHeight);
const [currentOption, setcurrentOption] = useState("Dashboard");
const [isMobile, setIsMobile] = useState(false);
const [selectedOpt, setSelectedOpt] = useState(0);
const navigate=useNavigate();
const [selected, setSelected] = useState(0);
const [isLoading,setLoading]=useState(false)
const [isLoadingSecond,setLoadingSecond]=useState(false)
const [menuSelected, setmenuSelected] = useState(0);

//for changing the position of sidenav after a certain height (start)
const rightPanelRef = useRef(null);
const [isFixed, setIsFixed] = useState(true);
const [thresholdHeight, setThresholdHeight] = useState(0);
const footerHelperRef = useRef(null);
const [footerHelp, setfooterHelp] = useState(false);


useEffect(() => {
  const observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) {
        setfooterHelp(true);
      } else {
        setfooterHelp(false);
      }
    },
    {
      root: null,
      rootMargin: '0px',
      threshold: 0.1 // Change this threshold value as needed
    }
  );

  if (footerHelperRef.current) {
    observer.observe(footerHelperRef.current);
  }

  return () => {
    if (footerHelperRef.current) {
      observer.unobserve(footerHelperRef.current);
    }
  };
}, []);




const handleDivClick = (index) => {
  setSelected(index);
};

const [searchQuery, setSearchQuery] = useState('');

const handleInputChange = (event) => {
  setSearchQuery(event.target.value);
};




useEffect(() => {
    const handleResize = () => {
      setLeftPaneHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



const [isLoggedin, setisLoggedin] = useState(false); 
const [userId, setUserId] = useState(null);
const [accessToken, setaccessToken] = useState(null)

const userName = localStorage.getItem('user_name');



useEffect(() => {
  const userIdFromLocalStorage = localStorage.getItem('user_id');
  const sessionLSFromLocalStorage = localStorage.getItem('sessionLS');
  const AccessTokenFromLocalStorage = localStorage.getItem('accestoken');
  //&& sessionLSFromLocalStorage


  if (userIdFromLocalStorage ) {
    setisLoggedin(true);
    setUserId(userIdFromLocalStorage);
    setaccessToken(AccessTokenFromLocalStorage);
  }
}, [isLoggedin]); 

const [investmentDetails, setInvestmentDetails] = useState(null);
useEffect(() => {
  const fetchData = async () => {
    try {
      setLoading(true);
      if (userId && accessToken) { // Ensure both userId and accessToken are available
        const response = await fetch(`${ApiBaseUrl}/fr-user/get-investment-details?user_id=${userId}`, {
          headers: {
            Authorization: accessToken, 
            'Content-Type': 'application/json', 
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch investment details');
        }
        const data = await response.json();
        setInvestmentDetails(data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching investment details:', error);
    }
  };

  fetchData();
}, [userId, accessToken]);

const sideNavRef = useRef(null);
useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1020px)');
    setIsMobile(mediaQuery.matches);

    const handleResize = () => {
      setIsMobile(mediaQuery.matches);
    };

    mediaQuery.addListener(handleResize);

    return () => {
      mediaQuery.removeListener(handleResize);
    };
  }, []);



  const [isEmailVerified, setisEmailVerified] = useState(false);
  const [isMobileVerified,setisMobileVerified]=useState(false)
  const [isKYCVerified, setisKYCVerified] = useState(false);
  const [showEmailUpdateAlert,setShowEmailUpdateAlert]=useState(false)
  const [showMobileUpdateAlert,setShowMobileUpdateAlert]=useState(false)

const handleVerify=()=>{
  if(!isEmailVerified)
  {
    setShowEmailUpdateAlert(true)
    setTimeout(() => {
      setShowEmailUpdateAlert(false);
      navigate("/user/profile")
    }, 2000);
   
  }
  else if(!isMobileVerified)
    {
      setShowMobileUpdateAlert(true)
      setTimeout(() => {
        setShowMobileUpdateAlert(false);
        navigate("/user/profile")
      }, 2000);
     
    }
  else if (isEmailVerified && isMobileVerified)
  {
    navigate("/user/kyc")
  }
}




useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingSecond(true);
        if (userId && accessToken) {
          const response = await fetch(`${ApiBaseUrl}/fr-user/fetch-profile-and-kyc?user_id=${userId}`, {
            headers: {
              Authorization: accessToken, 
              'Content-Type': 'application/json', 
            },
          });
          // if (!response.ok) {
          //   throw new Error('Failed to fetch profile and KYC details');
          // }
          const data = await response.json();

          if (!data.success && data.data === 'INVALID TOKEN OR USER ID MISMATCH') {
           
            handlelogout(navigate); // Log out the user
          }


          if (data.data.profile.email === null || data.data.profile.email === undefined) {
            setisEmailVerified(false);
          } else {
            setisEmailVerified(true);
          }

          if (data.data.profile.mobile === null || data.data.profile.mobile === undefined) {
            setisMobileVerified(false);
          } else {
            setisMobileVerified(true);
          }
          if (data.data.kyc?.kyc_status === "REQUESTED" || data.data.kyc?.kyc_status === "APPROVED") {
            setisKYCVerified(true);
          }        
          else{
            setisKYCVerified(false);
          }
          setLoadingSecond(false);

        }
      } catch (error) {
        setLoadingSecond(false);
        console.error('Error fetching profile and KYC details:', error);
      }
    };
  
    fetchData();
  }, [userId, accessToken]);
  

  

//   useEffect(() => {
//     console.log("use Effect")
//     const handlePopState = () => {
//      console.log("pop state")
//       const previousRoute = localStorage.getItem('previousRoute');
    
//       if (previousRoute === '/purchase') {
//         console.log("purchase")
//         // Clear the previousRoute to prevent an infinite loop
//         window.history.go(-3);
//         localStorage.removeItem('previousRoute');
//       }
//       else{
//         window.history.go(-1);
//       }
// };
//  window.addEventListener('popstate', handlePopState);
//  // Push initial state
// window.history.pushState({ modalOpened: false }, '');

// Cleanup function
  //   return () => {
      
  //     window.removeEventListener('popstate', handlePopState);
  //   };
  // }, []);


  if (isMobile) {
    return <DashboardMobile />;
  }




  return (
    <>

{
  showEmailUpdateAlert && (
    <WarningAlert message="Please Update Your Email" />
  )
}

{
  showMobileUpdateAlert && (
    <WarningAlert message="Please Update Your Mobile no" />
  )
}


    <div className='parent font-nunito mt-16'>
    <div className=''><Navbar isLoggedin={isLoggedin} setisLoggedin={setisLoggedin} setUserId={setUserId} userId={userId} menuSelected={menuSelected} setmenuSelected={setmenuSelected}/></div>
    {/*First col start */}
      <div className=' flex flex-row justify-center firstHelper'>
        <div className='bg-white helperContainer text-black flex flex-row'>
        <div className='w-100'><Navbar isLoggedin={isLoggedin} setisLoggedin={setisLoggedin} setUserId={setUserId} userId={userId}/> </div>
        {/*Left part start */}

        {/* footer changes */}
          <div 
          ref={sideNavRef}
          className={`leftPaneCollectionDash  text-black flex flex-col ml-2 -mt-8 px-4 py-8 pb-12`}
          style={{ 
            width:"240px",
            borderRadius:"0px",
            backgroundColor: "rgba(244, 243, 245, 1)",
           // position: footerHelp ? 'relative' : 'fixed',
       position:"fixed",
            height: leftPaneHeight, 
            overflowY: 'auto' 
          }}>
          <DashboardSideNav currentOption={currentOption} selectedOpt={selectedOpt} setSelectedOpt={setSelectedOpt}/>
          </div>
        {/*Left part end*/}



          {/*Right part start */}


{/* footer changes */}
{/* {`${footerHelp?"noPaddingHelper":"paddingHelperPhoneDash"}`} */}

          <div className={`paddingHelperPhoneDash`}>
          {!isEmailVerified&& !isLoadingSecond &&(
            <div className='bg-white  rightPaneCollection text-black flex flex-col pl-2 pr-2 -mt-1' style={{width:"100%"}}>
            <div className='flex flex-row justify-center gap-2 h-6 verifyEmailBg cursor-pointer' style={{borderRadius:"40px"}}>
               
              
                <div className="tooltip">
                          
                          <IoMdInformationCircleOutline style={{height:"20px",color:"rgba(239, 176, 8, 1)"}} />
                             
                          </div>
              
              
                <div onClick={()=>navigate("/user/profile")} className='text-[12px] font-semibold flex flex-row justify-center'><span style={{color:"rgba(239, 176, 8, 1)"}}>Verify your Email first &nbsp;</span>– time to kick start your investment journey !</div>
            </div>
        </div>

          )}




{!isMobileVerified&& !isLoadingSecond &&(
            <div className='bg-white  rightPaneCollection text-black flex flex-col pl-2 pr-2 -mt-1' style={{width:"100%"}}>
            <div className='flex flex-row justify-center gap-2 h-6 verifyEmailBg cursor-pointer' style={{borderRadius:"40px"}}>
               
              
                <div className="tooltip">
                          
                          <IoMdInformationCircleOutline style={{height:"20px",color:"rgba(239, 176, 8, 1)"}} />
                             
                          </div>
              
              
                <div onClick={()=>navigate("/user/profile")} className='text-[12px] font-semibold flex flex-row justify-center'><span style={{color:"rgba(239, 176, 8, 1)"}}>Verify your Mobile No first &nbsp;</span>– time to kick start your investment journey !</div>
            </div>
        </div>

          )}
            
            


            
  
            {currentOption==='Dashboard'&&(
                <div className='flex flex-col'>


{/*First row start */}
{isLoading ? (
  <div className="alertBar" style={{ height: "100vh" }}>
    <BeatLoader color={'#123abc'} loading={isLoading} />
  
  </div>
) : null}

{isKYCVerified&& !isLoadingSecond &&(
  <div className=' flex flex-row h-[60px] justify-between bg-white mt-2 font-nunito ' style={{borderRadius:"20px"}}>
           

  <div className='flex flex-row'>

    <div className='flex flex-row px-4'>
      <div className='flex flex-col justify-center'>
          <div className=' text-black text-[24px] font-bold text-start'><span className='text-[#D328A7]'>Hi, </span>{userName==="null"?"User":userName}</div>
          <div className=' text-black text-[16px] font-normal mt-[8px] text-start'>Here is your list of invested properties.</div>
      </div>
    </div>
    </div>


  </div>
)}
{!isKYCVerified&& !isLoadingSecond && (
  <div className=' flex flex-row h-[120px] justify-between bg-black mt-2' style={{borderRadius:"20px"}}>
           

  <div className='flex flex-row'>

    <div className='flex flex-row'>
    <div className=' text-white  ml-[20px] flex flex-col justify-center'><img src={DashboardList}/></div>
      <div className='flex flex-col justify-center'>
          <div className=' text-white text-[24px] font-bold text-start'>Hi, {userName==="null"?"User":userName}</div>
          <div className=' text-white text-[16px] font-normal mt-[8px] text-start'>Please complete your KYC to start investing. You could be the next biggest investor.</div>
      </div>
    </div>
    </div>

    <div className='flex flex-row mr-[20px]'>
      <div className=' flex flex-col justify-center cursor-pointer'>
       
          <div className='flex flex-row bg-white h-[36.46px] rounded-lg px-[10px] ' onClick={handleVerify}>
              <div className=' text-black mr-[12px] flex flex-col justify-center font-semibold w-32'>Verify Yourself</div>
              <div className='borde border-white text-black flex flex-col justify-center'><img src={lock} className='h-4'/></div>
          </div>
          
      </div>
    </div>
  </div>
)}

{/*First row end */}

{/*Second row start */}

{ isLoading ? (
  <div className="alertBar" style={{height:"100vh"}}> <BeatLoader color={'#123abc'} loading={isLoading}  /></div>
) : 
(


                <div className=' flex flex-col mt-[20px]'>
                
                    <div className=' flex flex-row justify-between gap-6 h-[86px]' >
                        <div className=' topLeft dashShadow flex flex-row justify-between ' style={{borderRadius:"10px"}}>
                            <div className=' flex flex-col justify-center ml-[31px]'>
                                <div className=' text-start text-[22px] font-bold'>{investmentDetails && investmentDetails.data.property_holding}</div>
                                <div className=' mt-1 text-[#565656] text-[12px] font-normal'>Properties Holding</div>
                            </div>
                            <div className=' flex flex-row  mr-[10px] '>
                            <div className=' flex flex-col justify-center '>
                              <img className='iconDash' src={propertyHolding}/> 
                              </div>
                              <div className="tooltip mt-2">
                          
                          <IoMdInformationCircleOutline style={{fontSize:"12px",color:" #D328A7"}} />
                              <span className="tooltiptextLeft">

                                <p className='text-blue text-sm'>What is Property Holding?</p>
                                <p className='text-gray text-xs'>Property holdings are the total number of properties held by an investor.
                            </p>
                              </span>
                          </div>   
                              
                              
                              
                              
                              
                              </div>

                            
                             
                        </div>

                        <div className=' topMiddle dashShadow flex flex-row justify-between' style={{borderRadius:"10px"}}>

                            <div className=' flex flex-col justify-center ml-[31px]'>
                                <div className=' text-start text-[22px] font-bold'>₹ {investmentDetails && investmentDetails.data.total_investment}</div>
                                <div className=' mt-1 text-start text-[#565656] text-[12px] font-normal'>Total Investment</div>
                            </div>
                            <div className='flex flex-row mr-[10px]'>
                                <div className=' flex flex-col justify-center'><img className='iconDash' src={totalinvest}/></div>
                             
                                  

                           <div className="tooltip mt-2">
                          
                          <IoMdInformationCircleOutline style={{fontSize:"12px",color:" #D328A7"}} />
                              <span className="tooltiptextLeft">

                                <p className='text-blue text-sm'>What is Total Investment?</p>
                                <p className='text-gray text-xs'>Total investment refers to the 
                                combined funds across all investments.
                            </p>
                              </span>
                          </div>

                                  
                                 
                            </div>
                        </div>


                            <div className=' topMiddle dashShadow flex flex-row justify-between' style={{borderRadius:"10px"}}>

                            <div className=' flex flex-col justify-center ml-[31px]'>
                                <div className=' text-start text-[22px] font-bold'>₹ {investmentDetails && investmentDetails.data.current_value}</div>
                                <div className=' mt-1 text-start text-[#565656] text-[12px] font-normal'>Current Value</div>
                            </div>
                            <div className='flex flex-row mr-[10px]'>
                                <div className=' flex flex-col justify-center'><img className='iconDash' src={currentValue}/></div>
                                <div className="tooltip mt-2">
                          
                          <IoMdInformationCircleOutline style={{fontSize:"12px",color:" #D328A7"}} />
                              <span className="tooltiptextLeft">

                                <p className='text-blue text-sm'>What is Current Value?</p>
                                <p className='text-gray text-xs'>
                                The current value refers to the present market worth 
                                of all investments i.e ( Investment + Appreciation )
                            </p>
                              </span>
                          </div>
                            </div>
                        </div>

                        </div>

                    <div className=' flex flex-row justify-between gap-5 h-[86px] mt-[18px]'>
                        <div className=' topMiddle dashShadow flex flex-row justify-between' style={{borderRadius:"10px"}}>

                            <div className=' flex flex-col justify-center ml-[31px]'>
                                <div className=' text-start text-[22px] font-bold'>₹ {investmentDetails && investmentDetails.data.total_returns}</div>
                                <div className=' mt-1 text-start text-[#565656] text-[12px] font-normal'>Total Returns</div>
                            </div>
                            <div className='flex flex-row mr-[10px]'>
                                <div className=' flex flex-col justify-center'><img className='iconDash' src={totalReturn}/></div>
                                <div className="tooltip mt-2">
                          
                          <IoMdInformationCircleOutline style={{fontSize:"12px",color:" #D328A7"}} />
                              <span className="tooltiptextLeft">

                                <p className='text-blue text-sm'>What is Total Returns?</p>
                                <p className='text-gray text-sm'>It represents the actual return over the total investment.
                            </p>
                              </span>
                          </div>
                            </div>
                        </div>


                        <div className=' topMiddle dashShadow flex flex-row justify-between' style={{borderRadius:"10px"}}>

                            <div className=' flex flex-col justify-center ml-[31px]'>
                                <div className=' text-start text-[22px] font-bold'>{investmentDetails && investmentDetails.data.average_capital_gain}%</div>
                                <div className=' mt-1 text-start text-[#565656] text-[12px] font-normal'>Average Potential Gain</div>
                            </div>
                            <div className='flex flex-row mr-[10px]'>
                                <div className=' flex flex-col justify-center'><img className='iconDash' src={Dashboard5}/></div>
                                <div className="tooltip mt-2">
                          
                          <IoMdInformationCircleOutline style={{fontSize:"12px",color:" #D328A7"}} />
                              <span className="tooltiptextLeft">

                                <p className='text-blue text-sm'>What is Average Potential Gain?</p>
                                <p className='text-gray text-xs'>
                                Average Average Potential is the typical increase in property value or profit over a given period.
                            </p>
                              </span>
                          </div>
                            </div>
                        </div>


                        <div className=' topMiddle dashShadow flex flex-row justify-between' style={{borderRadius:"10px"}}>

                            <div className=' flex flex-col justify-center ml-[31px]'>
                                <div className=' text-start text-[22px] font-bold'>{investmentDetails && investmentDetails.data.average_IRR}%</div>
                                <div className=' mt-1 text-start text-[#565656] text-[12px] font-normal'>Average IRR</div>
                            </div>
                            <div className='flex flex-row mr-[10px]'>
                                <div className=' flex flex-col justify-center'><img className='iconDash' src={avgIRR}/></div>
                                <div className="tooltip mt-2">
                          
                          <IoMdInformationCircleOutline style={{fontSize:"12px",color:" #D328A7"}} />
                              <span className="tooltiptextLeft">

                                <p className='text-blue text-sm'>What is Average IRR?</p>
                                <p className='text-gray text-xs'>
                                Average IRR is the average annual return of all investments.

                            </p>
                              </span>
                          </div>
                            </div>
                        </div>


                        <div className=' topMiddle dashShadow flex flex-row justify-between' style={{borderRadius:"10px"}}>

                            <div className=' flex flex-col justify-center ml-[31px]'>
                                <div className=' text-start text-[22px] font-bold'>{investmentDetails && investmentDetails.data.average_yield}%</div>
                                <div className=' mt-1 text-start text-[#565656] text-[12px] font-normal'>Average Yield</div>
                            </div>
                            <div className='flex flex-row mr-[10px]'>
                                <div className=' flex flex-col justify-center'><img className='iconDash' src={avgYield}/></div>
                                <div className="tooltip mt-2">
                          
                          <IoMdInformationCircleOutline style={{fontSize:"12px",color:" #D328A7"}} />
                              <span className="tooltiptextLeft">

                                <p className='text-blue text-sm'>What is Average Yield?</p>
                                <p className='text-gray text-xs'>The typical percentage return on investment over a given period, averaged across all investments.
                            </p>
                              </span>
                          </div>
                            </div>
                        </div>


                    </div>
                
                </div>

                )}
 
{/*Second row end */}


{/*Third row Start */}
<div className='text-start text-2xl font-bold mt-[30px]'>My Portfolio</div>
{/*Third row end */}

{/*Fourth row Start */}
<div className='flex flex-row  fourthColGap mt-3'>
      <div
        className={`flex flex-row  px-4 py-1 text-[14px] font-normal rounded-lg borderPortfolio cursor-pointer ${selected === 0 ? 'selectedPortfolio' : ''}`}
        onClick={() => handleDivClick(0)}
      >
        Booked Properties
      </div>
      <div
        className={`flex flex-row  px-4 py-1 text-[14px] font-normal rounded-lg borderPortfolio cursor-pointer ${selected === 1 ? 'selectedPortfolio' : ''}`}
        onClick={() => handleDivClick(1)}
      >
        Available Properties
      </div>
      <div
        className={`flex flex-row  px-4 py-1 text-[14px] font-normal rounded-lg borderPortfolio cursor-pointer ${selected === 2 ? 'selectedPortfolio' : ''}`}
        onClick={() => handleDivClick(2)}
      >
        Resale Properties
      </div>
      <div
        className={`flex flex-row  px-4 py-1 text-[14px] font-normal rounded-lg borderPortfolio cursor-pointer ${selected === 3 ? 'selectedPortfolio' : ''}`}
        onClick={() => handleDivClick(3)}
      >
        Sold out Properties
      </div>
    </div>
{/*Fourth row end */}

{/*Horizontal Line start */}
    <div className='flex flex-row justify-center enquiryParent mt-3'>
         <hr style={{ color: 'rgba(217, 217, 217, 1)', width: '100%' }}></hr>
    </div>
{/*Horizontal Line end */}

{/*Fifth row start */}
{selected === 0 && <BookedProperties />}

{selected === 1 &&  <AvailableProperties />   
    }



 {selected === 2 &&  
 
<ResalePropWeb/>   
          
          
          }

   {selected === 3 &&   
<>
<SoldoutPropWeb/>
</>
          }

{/*Sixth row end */}

                </div>
            )}

            {currentOption==='Myprofile'&&(
                <div>
                    <ProfilePage/>
                </div>
            )}

            {currentOption==='Wishlist'&&(
                <div>This is Wishlist</div>
            )}

            {currentOption==='Overview'&&(
                <div>This is Overview</div>
            )}

            {currentOption==='Analytics'&&(
                <div>This is Analytics</div>
            )}

            {currentOption==='Transactions'&&(
                <div>This is Transactions</div>
            )}

            {currentOption==='Mymanager'&&(
                <div>This is My manager</div>
            )}

            {currentOption==='Settings'&&(
                <div>This is Settings</div>
            )}
          












          </div>

    
          {/*Right part end */}





        </div>
      </div>

      {/* <div ref={footerHelperRef} className="footerHelper">
      <footer style={{height:"#000"}} className='dashboardFooter'></footer>
      </div> */}

      
    </div>

</>
  );
}

export default Dashboard;
