import React, { useState, useRef, useEffect } from "react";
import "./Index.css";
import logo from "../../Assets/logo/loog_new_light.svg";
import { IoIosArrowDown } from "react-icons/io";
import { RiCloseFill } from "react-icons/ri";
import { RiArrowRightSLine } from "react-icons/ri";
import Profile from "../../Assets/profile.svg";

import { Link, useLocation, useNavigate } from "react-router-dom";

import { FiChevronDown } from "react-icons/fi";
import { RxDashboard } from "react-icons/rx";
import { CgProfile } from "react-icons/cg";
import { MdOutlineChecklist } from "react-icons/md";

import { TbBuildingSkyscraper, TbBulb } from "react-icons/tb";
import { MdOutlinePeopleAlt } from "react-icons/md";
import { HiOutlineCurrencyRupee, HiOutlineLightBulb } from "react-icons/hi2";
import { FaPowerOff } from "react-icons/fa6";

import HowItWorks from "../../Assets/navbarPopup/howitworks2.png";
import Blogs from "../../Assets/navbarPopup/blog3.png";
import FAQs from "../../Assets/navbarPopup/faqs4.png";

import abputIcon from "../../Assets/navbarPopup/aboutIcon.png";
import leadericon from "../../Assets/navbarPopup/leadership.png";
import AIiconMob from "../../Assets/Dashboard/AIiconMob.svg";
import projMobIcon from "../../Assets/Dashboard/ProjIcMob.svg";

import { AiOutlineYoutube } from "react-icons/ai";
import hambergerIcon from "../../Assets/icons/hamberger-svg.svg";
import profleIconM from "../../Assets/Dashboard/profile_setup_mobile.svg";
import kycIconM from "../../Assets/Dashboard/kyc_icon_mobile.svg";
import bankIconM from "../../Assets/Dashboard/bank_icon_mobile.svg";
import nomIconM from "../../Assets/Dashboard/nominee_icon_mobile.svg";

function Navbar(props) {
  const [currentState, setcurrentState] = useState(0);
  const [dropdownOpen, setdropdownOpen] = useState(false);
  const [profileOpen, setprofileOpen] = useState(false);
  const navSecondRef = useRef(null);
  const proIconRef = useRef(null);
  const [ismobile, setismobile] = useState(false);
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const navigate = useNavigate();
  const [helpOpen, sethelpOpen] = useState(false);
  const [profileOpenMob, setprofileOpenMob] = useState(false);
  const [profileSubMenuOpen, setProfileSubMenuOpen] = useState(false);

  const [AIOpenMob, setAIOpenMob] = useState(false);
  const [AISubMenuOpen, setAISubMenuOpen] = useState(false);

  const [activeMenuItem, setActiveMenuItem] = useState(""); //active menu
  const [abtRightArrow, setAbtRightArrow] = useState(true);
  const [KnowMoreRightArrow, setKnowMoreRightArrow] = useState(true);
  const [toggleAboutDropdown, settoggleAboutDropdown] = useState(false);

  const loggedInusername = localStorage.getItem("user_name") || "User";
  const accesstoken = localStorage.getItem("accestoken");
  const encryptedToken = encodeURIComponent(btoa(accesstoken));
  const location = useLocation();
  // const logoUrl = `https://www.propftx.com/${accesstoken ? `?token=${encryptedToken}` : ''}`;

  useEffect(() => {
    const handleResize = () => {
      setismobile(window.innerWidth < 1050);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const userIdFromLocalStorage = localStorage.getItem("user_id");
    const sessionLSFromLocalStorage = localStorage.getItem("sessionLS");
    // && sessionLSFromLocalStorage
    if (userIdFromLocalStorage) {
      props.setisLoggedin(true);
      props.setUserId(userIdFromLocalStorage);
    }
  }, [props.isLoggedin]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (proIconRef.current && !proIconRef.current.contains(event.target)) {
        setprofileOpen(false);
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleDropdownHover = () => {
    setcurrentState(3);
    setwebHelp(!webHelp);
    setdropdownOpen(!dropdownOpen);
    // if(dropdownOpen){
    //   settoggleAboutDropdown(false)
    // }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".parentNavPopup") &&
        !event.target.closest(".navPopupHelper")
      ) {
        setwebHelp(false);
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleDropdownLeave = () => {
    setdropdownOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("user_id");
    localStorage.removeItem("sessionLS");
    localStorage.removeItem("accestoken");
    localStorage.removeItem("user_name");

    localStorage.removeItem("user_email");
    localStorage.removeItem("user_mobile_no");

    localStorage.removeItem("user_country_code");

    props.setisLoggedin(false);
    props.setUserId(null);
    document.cookie = `accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.propftx.com;`;
    document.cookie = `userName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.propftx.com;`;

    navigate("/");
  };

  const handleToggleAboutMenu = () => {
    settoggleAboutDropdown((prev) => !prev);
    setAbtRightArrow((prev) => !prev);
  };

  const aboutdropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        aboutdropdownRef.current &&
        !aboutdropdownRef.current.contains(event.target)
      ) {
        settoggleAboutDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // const handleToggleAboutMenu = (isOpen) => {
  //   settoggleAboutDropdown(isOpen);
  // };
  const handleRedirectToDashboard = () => {
    navigate("/dashboard");
  };

  const toggleSideNav = () => {
    setSideNavOpen(!sideNavOpen);
  };
  const toggleHelp = () => {
    sethelpOpen(!helpOpen);
    setKnowMoreRightArrow((prev) => !prev);
  };
  const toggleMob = () => {
    setprofileOpenMob(!profileOpenMob);
  };

  //for setting active menu
  useEffect(() => {
    const path = window.location.pathname;
    const propertyDetailsPattern = /^\/[^\/]+\/[^\/]+\/[^\/]+$/;
    if (path.startsWith("/user/profile")) {
      setActiveMenuItem("profile");
      setProfileSubMenuOpen(true);
    } else if (path === "/user/kyc") {
      setActiveMenuItem("kyc");
      setProfileSubMenuOpen(true);
    } else if (path === "/user/nominee" || path === "/user/nominee/add") {
      setActiveMenuItem("nominee");
      setProfileSubMenuOpen(true);
    } else if (
      path === "/user/bank-details" ||
      path === "/user/bank-details/edit"
    ) {
      setActiveMenuItem("bank-details");
      setProfileSubMenuOpen(true);
    } else if (path === "/") {
      setcurrentState(1);
    } else if (propertyDetailsPattern.test(path)) {
      setcurrentState(1);
    } else if (path === "/user/projection") {
      setAISubMenuOpen(true);
      setActiveMenuItem("projection");
    } else if (path === "/user/advisor") {
      setAISubMenuOpen(true);
      setActiveMenuItem("advisor");
    } else {
      setActiveMenuItem("");
      setProfileSubMenuOpen(false);
    }
  }, []);

  const [webHelp, setwebHelp] = useState(false);

  return (
    <div className="navContainer">
      <div className="navParent font-nunito flex flex-row justify-between px-[24px] py-[19px] h-[60px]">
        {webHelp && (
          <div className="helpOverlayNavbar ">
            <div className="helpPopupNavbar border border-black">
              <div className="parentNavPopup  flex flex-row bg-white px-7 py-4 gap-12">
                <div className=" flex flex-col gap-2">
                  {/* <div className=' flex flex-row gap-2 cursor-pointer '>
              <div className=' sizeIconNavPopup mt-1'>
                <img src={FrInvestment}/>
                
        
              </div>
              <div className=' flex flex-col'>
                <div className=' text-[16px] font-medium text-start'>Fractional Investment</div>
                <div className=' text-[12px] font-light text-start text-[#787878]'>
               
                Learn how to make fractional investments <br/>
                in premium real estate assets with PropFTX and <br/>
                 enjoy rentals and capital appreciation, helping<br/>
                  you unlock new investment opportunities.<br/><br/>
               
               
               </div>
              </div>
            </div>
            <div className=' -mt-2 text-[#787878] '>- - - - - - - - - - - - - - - - - - - - - - - -</div> */}

                  <div className=" flex flex-row gap-2 -mt-1 cursor-pointer  ">
                    <div className=" sizeIconNavPopup mt-1">
                      <img src={HowItWorks} />
                    </div>
                    <div className=" flex flex-col">
                      <a href="https://www.propftx.com/how-it-works/">
                        <div className=" text-[16px] font-medium text-start">
                          How it works
                        </div>
                      </a>

                      <div className=" text-[12px] font-light text-start text-[#787878]">
                        Explore how fractional investments work with
                        <br />
                        PropFTX. Learn through our step-by-step guide and <br />
                        enjoy a seamless investment experience.
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className=" -mt-2 text-[#787878]">
                    - - - - - - - - - - - - - - - - - - - - - - - -
                  </div>
                  <div className=" flex flex-row gap-2 cursor-pointer">
                    <div className=" sizeIconNavPopup mt-1">
                      <img src={Blogs} />
                    </div>
                    <div className=" flex flex-col">
                      <a href="https://www.propftx.com/blog/">
                        <div className=" text-[16px] font-medium text-start">
                          Blogs
                        </div>
                      </a>

                      <div className=" text-[12px] font-light text-start text-[#787878]">
                        Read our latest blogs to gain insights into market{" "}
                        <br />
                        updates and a deeper understanding of fractional
                        <br />
                        ownership to stay ahead of your investment game.
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className=" -mt-2 text-[#787878]">
                    - - - - - - - - - - - - - - - - - - - - - - - -
                  </div>
                  <div className=" flex flex-row gap-2 cursor-pointer ">
                    <div className=" sizeIconNavPopup mt-1">
                      <img src={FAQs} />
                    </div>
                    <div className=" flex flex-col">
                      <a href="https://www.propftx.com/faq/">
                        <div className=" text-[16px] font-medium text-start">
                          FAQs
                        </div>
                      </a>
                      <div className=" text-[12px] font-light text-start text-[#787878]">
                        Find answers to your questions about PropFTX, how it
                        <br />
                        works, and how to get started on your investment
                        <br />
                        journey with us.
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div>
            <div className=' flex flex-col justify-center videoSizeNavPopup ' style={{height:"100%"}}>
                <img src={Video}/>
            </div>
          </div> */}
              </div>
            </div>
          </div>
        )}

        <Link to="https://www.propftx.com/">
          <div className="text-white navLogo">
            <img src={logo} className=" w-[130px]" alt="logo" />
          </div>
        </Link>

        {!ismobile && (
          <div className="text-white navMiddle flex flex-row gap-[45px] font-nunito">
            <div
              // onMouseEnter={() => handleToggleAboutMenu(true)} // Open dropdown on hover
              // onMouseLeave={() => handleToggleAboutMenu(false)}
              onClick={handleToggleAboutMenu}
              className={`text-[16px] flex  items-center gap-1 cursor-pointer ${
                currentState === 0 ? "activeNav" : ""
              }`}
              ref={aboutdropdownRef}
            >
              About Us <IoIosArrowDown />
            </div>

            {toggleAboutDropdown && (
              <div className=" bg-white aboutBox absolute top-[60px] shadow-md rounded-lg px-4 flex flex-col pl-4 gap-4 pb-2 cursor-pointer">
                <a
                  href="https://www.propftx.com/about-us/"
                  className="flex items-center gap-2.5 "
                >
                  <img src={abputIcon} alt="about" />
                  <div className="text-start py-3 text-blue">About Company</div>
                </a>
                <a
                  href="https://www.propftx.com/leadership/"
                  className="flex items-center gap-2.5 "
                >
                  <img src={leadericon} alt="about" />
                  <div className="text-start text-blue">Leadership</div>
                </a>
              </div>
            )}

            <Link to="/">
              <div
                className={`text-[16px]  cursor-pointer ${
                  currentState === 1 ? "activeNav" : ""
                }`}
                onClick={() => setcurrentState(1)}
              >
                Properties
              </div>
            </Link>

            <div
              className={`navPopupHelper flex flex-col z-50 navSecond cursor-pointer h-12 ${
                currentState === 3 ? "activeNav" : ""
              } `}
              ref={navSecondRef}
              onClick={handleDropdownHover}
            >
              <div className={`flex flex-row text-[16px]`}>
                Know More
                <div className="flex flex-col justify-center ml-1">
                  <IoIosArrowDown />
                </div>
              </div>

              {dropdownOpen && (
                <div className=" flex flex-col bg-[#091526] rounded-lg">
                  {/*Add pop Up */}
                </div>
              )}
            </div>
            <a href="https://www.propftx.com/contact/">
              <div
                className={`text-[16px]  cursor-pointer ${
                  currentState === 2 ? "activeNav" : ""
                }`}
                onClick={() => setcurrentState(2)}
              >
                Contact Us
              </div>
            </a>
          </div>
        )}

        {!ismobile && (
          <div className="text-white navLogin flex flex-row gap-5 -mt-1.5">
            <div
              className="flex flex-col justify-center bg-[#78F9FF] text-[14px] font-normal rounded-md text-black px-[40px] py-[6px] cursor-pointer"
              style={{ display: "none" }}
            >
              Club Access
            </div>
            {!props.isLoggedin && (
              <div
                className="border border-white flex flex-col justify-center text-[14px] font-normal rounded-md px-[40px] py-[6px] w-40 cursor-pointer"
                onClick={() =>
                  navigate("/login", { state: { from: location } })
                }
              >
                Login/Signup
              </div>
            )}
            {props.isLoggedin && (
              <div className="flex flex-col">
                <div className="flex flex-row justify-center rounded-md gap-12 py-[6px] w-40">
                  <div className="flex flex-col justify-center">
                    {/* <img src={Notification} alt='Notification' /> */}
                  </div>
                  <div
                    ref={proIconRef}
                    className="-mt-1 h-[30px] w-[30px] p-1.5 bg-[#D4D4D4] rounded-2xl flex flex-col justify-center proIcon cursor-pointer"
                    onClick={() => setprofileOpen(!profileOpen)}
                  >
                    <img src={Profile} alt="Profile" />
                  </div>
                </div>
                {profileOpen && (
                  <div className=" bg-[#091526] rounded-lg mt-1 -ml-12 mr-12 flex flex-col pl-4 gap-4 pb-2 cursor-pointer">
                    <div
                      className="text-start"
                      onClick={handleRedirectToDashboard}
                    >
                      Dashboard
                    </div>
                    <div className="text-start" onClick={handleLogout}>
                      Logout
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {ismobile && (
          <div className="text-white navLogin flex flex-row gap-5 -mt-1.5 font-nunito">
            <div
              className=" hamburger cursor-pointer flex flex-col justify-center"
              onClick={toggleSideNav}
            >
              {/* <GiHamburgerMenu style={{height:"25px",width:"25px"}} /> */}

              {/* <span class="material-symbols-outlined" style={{fontWeight:"300"}}>
menu
</span> */}
              <img src={hambergerIcon} />
            </div>
          </div>
        )}

        {ismobile && (
          <div
            className={`absolute bg-[#091526] text-white top-0 right-0 w-[240px] sideNav cursor-pointer ${
              sideNavOpen ? "open" : "closed"
            }`}
          >
            {/* SideNav content */}
            <div className="py-5 px-7 bg-[#091526]">
              <div
                className="text-lg font-bold flex flex-row justify-between  items-center"
                onClick={toggleSideNav}
              >
                <img src={logo} style={{ width: "120px" }} alt="propftx" />
                <RiCloseFill style={{ fontSize: "24px" }} />
              </div>

              <div className="flex flex-col menuBoxMob justify-between">
                {/* all menu */}

                <div className=" mt-4 flex flex-col gap-[16px] overflow-y-auto no-scroll">
                  {!props.isLoggedin && (
                    <div
                      className="border border-white flex flex-col text-left  text-[14px] font-normal rounded-md px-[40px] py-[6px]  cursor-pointer Mob mobloginBtn"
                      onClick={() =>
                        navigate("/login", { state: { from: location } })
                      }
                    >
                      Login/Signup
                    </div>
                  )}

                  {props.isLoggedin && (
                    <div className="flex flex-col justify-between ">
                      <div className="">
                        <div
                          onClick={toggleMob}
                          className="flex w-full flex-row border border-white justify-between rounded-lg gap-2 py-[6px]  pr-2 pl-2 cursor-pointer h-[44px]"
                        >
                          <div className="flex   flex-row justify-between gap-4 items-center">
                            {/* <div className='flex flex-col justify-center'>
                        <img src={Notification} alt='Notification' />
                      </div> */}
                            <div
                              ref={proIconRef}
                              className=" h-[25px] w-[25px] p-1.5 bg-[#D4D4D4] rounded-2xl flex flex-col justify-center proIcon cursor-pointer"
                              onClick={toggleMob}
                            >
                              <img src={Profile} alt="Profile" />
                            </div>
                            <p className="text-base font-semibold">
                              {loggedInusername === "null"
                                ? "User"
                                : loggedInusername}
                            </p>
                          </div>
                          <div className="flex  flex-col justify-center optActiveColorMob cursor-pointer">
                            <FiChevronDown style={{ fontSize: "24px" }} />
                          </div>
                        </div>

                        {profileOpenMob && (
                          <div className=" cursor-pointer ">
                            <div
                              className="flex flex-row ml-7 gap-5 mt-[28px] optActiveColorMob cursor-pointer"
                              onClick={() => {
                                navigate("/dashboard");
                              }}
                            >
                              <div className={`flex flex-col justify-center `}>
                                <RxDashboard />
                              </div>
                              <div className={` text-[16px] font-basic  `}>
                                Dashboard
                              </div>
                            </div>

                            <div
                              className="flex flex-row ml-7 items-center justify-between gap-5 mt-[10px] optActiveColorMob cursor-pointer"
                              onClick={() =>
                                setProfileSubMenuOpen(!profileSubMenuOpen)
                              }
                            >
                              <div className="flex gap-4 items-center">
                                <div
                                  className={`flex flex-col justify-center  `}
                                >
                                  <CgProfile />
                                </div>
                                <div className={` text-[16px] font-basic  `}>
                                  My Profile
                                </div>
                              </div>

                              {profileSubMenuOpen ? (
                                <FiChevronDown style={{ fontSize: "24px" }} />
                              ) : (
                                <RiArrowRightSLine
                                  style={{ fontSize: "24px" }}
                                />
                              )}
                            </div>

                            {/* profile-submenu */}
                            {profileSubMenuOpen && (
                              <div className="flex flex-col  gap-3 py-2 pl-7 ml-7">
                                <Link
                                  to="/user/profile"
                                  className="flex items-center gap-2"
                                >
                                  <img src={profleIconM} alt="profile" />
                                  <p
                                    className={`text-sm text-left ${
                                      activeMenuItem === "profile"
                                        ? "text-secondary"
                                        : "text-white"
                                    }`}
                                    onClick={() => setActiveMenuItem("profile")}
                                  >
                                    Profile Setup
                                  </p>
                                </Link>

                                <Link
                                  to="/user/kyc"
                                  className="flex items-center gap-2"
                                >
                                  <img src={kycIconM} alt="profile" />
                                  <p
                                    className={`text-sm text-left ${
                                      activeMenuItem === "kyc"
                                        ? "text-secondary"
                                        : "text-white"
                                    }`}
                                    onClick={() => setActiveMenuItem("kyc")}
                                  >
                                    KYC
                                  </p>
                                </Link>
                                <Link
                                  to="/user/bank-details"
                                  className="flex items-center gap-2"
                                >
                                  <img src={bankIconM} alt="profile" />
                                  <p
                                    className={`text-sm text-left ${
                                      activeMenuItem === "bank-details"
                                        ? "text-secondary"
                                        : "text-white"
                                    }`}
                                    onClick={() =>
                                      setActiveMenuItem("bank-details")
                                    }
                                  >
                                    Bank Details
                                  </p>
                                </Link>
                                <Link
                                  to="/user/nominee"
                                  className="flex items-center gap-2"
                                >
                                  <img src={nomIconM} alt="profile" />
                                  <p
                                    className={`text-sm text-left ${
                                      activeMenuItem === "nominee"
                                        ? "text-secondary"
                                        : "text-white"
                                    }`}
                                    onClick={() => setActiveMenuItem("nominee")}
                                  >
                                    Nominee{" "}
                                  </p>
                                </Link>
                              </div>
                            )}

                            <div
                              className="flex flex-row ml-7 gap-5 mt-[10px] optActiveColorMob cursor-pointer"
                              onClick={() => {
                                navigate("/user/wishlist");
                              }}
                            >
                              <div className={`flex flex-col justify-center `}>
                                <MdOutlineChecklist />
                              </div>
                              <div className={` text-[16px] font-basic `}>
                                Wishlist
                              </div>
                            </div>
                            {/* onClick={()=>{navigate("/user/overview")}} */}
                            <div className="flex flex-row ml-7 justify-between mt-[10px] optActiveColorMob cursor-pointer ">
                              <div className="flex gap-4 items-center ">
                                <div
                                  className={`flex flex-col justify-center  `}
                                >
                                  <TbBuildingSkyscraper />
                                </div>
                                <div className={` text-[16px] font-basic  `}>
                                  Overview
                                </div>
                              </div>
                              <div className="comingsoonClass">Coming Soon</div>
                            </div>

                            {/* AI-Navigator-mobile */}
                            <div
                              className="flex flex-row ml-7 items-center justify-between gap-5 mt-[10px] optActiveColorMob cursor-pointer"
                              onClick={() => setAISubMenuOpen(!AISubMenuOpen)}
                            >
                              <div className="flex gap-4 items-center">
                                <div
                                  className={`flex flex-col justify-center  `}
                                >
                                  <img src={AIiconMob} />
                                </div>
                                <div className={` text-[16px] font-basic  `}>
                                  AI Navigator
                                </div>
                              </div>

                              {AISubMenuOpen ? (
                                <FiChevronDown style={{ fontSize: "24px" }} />
                              ) : (
                                <RiArrowRightSLine
                                  style={{ fontSize: "24px" }}
                                />
                              )}
                            </div>

                            {/* profile-submenu */}
                            {AISubMenuOpen && (
                              <div className="flex flex-col  gap-3 py-2  ml-10">
                                <Link to="/user/projection">
                                  <div className="flex items-center justify-start gap-2">
                                    <img src={projMobIcon} />
                                    <p
                                      className={`text-sm text-left ${
                                        activeMenuItem === "projection"
                                          ? "text-secondary"
                                          : "text-white"
                                      }`}
                                      onClick={() =>
                                        setActiveMenuItem("projection")
                                      }
                                    >
                                      Price Projections
                                    </p>
                                  </div>
                                </Link>

                                {/* <Link to="/user/advisor">
                                  <div className="flex items-center justify-start gap-2">
                                    <TbBulb color="#fff" />
                                    <p
                                      className={`text-sm text-left ${
                                        activeMenuItem === "advisor"
                                          ? "text-secondary"
                                          : "text-white"
                                      }`}
                                      onClick={() =>
                                        setActiveMenuItem("advisor")
                                      }
                                    >
                                      Smart Advisor
                                    </p>
                                  </div>
                                </Link> */}
                              </div>
                            )}
                            {/* AI-Navigator-mobile-end */}

                            {/* <div className="flex flex-row ml-7 justify-between  gap-5 mt-[10px] optActiveColorMob cursor-pointer">
                              <div
                                className="flex gap-4 items-center "
                                onClick={() => {
                                  navigate("/user/projection");
                                }}
                              >
                                <div
                                  className={`flex flex-col justify-center  `}
                                >
                                  <LuBarChart3 />
                                </div>
                                <div className={` text-[16px] font-basic  `}>
                                  Projection
                                </div>
                              </div>
                            </div> */}

                            <div
                              className="flex flex-row ml-7 gap-5 mt-[10px] optActiveColorMob cursor-pointer"
                              onClick={() => {
                                navigate("/user/transactions");
                              }}
                            >
                              <div className={`flex flex-col justify-center  `}>
                                <HiOutlineCurrencyRupee />
                              </div>
                              <div className={` text-[16px] font-basic `}>
                                Transactions{" "}
                              </div>
                            </div>
                            {/* onClick={()=>{navigate("/user/mymanager")}} */}
                            <div className="flex flex-row ml-7 gap-5 mt-[10px] justify-between optActiveColorMob cursor-pointer">
                              <div className="flex gap-4 items-center ">
                                <div
                                  className={`flex flex-col justify-center  `}
                                >
                                  <MdOutlinePeopleAlt />
                                </div>
                                <div className={` text-[16px] font-basic  `}>
                                  My Manager{" "}
                                </div>
                              </div>
                              <div className="comingsoonClass">Coming Soon</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <div
                    onClick={handleToggleAboutMenu}
                    className="text-start pb-2 text-[16px] border border-[#cac4c43f] border-t-0 border-x-0 justify-between flex items-center gap-2 font-basic optActiveColorMob cursor-pointer"
                  >
                    About Us
                    {abtRightArrow ? (
                      <RiArrowRightSLine style={{ fontSize: "24px" }} />
                    ) : (
                      <FiChevronDown style={{ fontSize: "24px" }} />
                    )}
                  </div>

                  {toggleAboutDropdown && (
                    <div className=" bg-[#091526]  rounded-lg flex flex-col pl-4 gap-1 pb-2 cursor-pointer">
                      <a href="https://www.propftx.com/about-us/">
                        <div className="text-start ">About Company</div>
                      </a>
                      <a href="https://www.propftx.com/leadership/">
                        <div className="text-start">Leadership</div>
                      </a>
                    </div>
                  )}

                  <Link to="/">
                    <div className=" text-start  text-[16px] font-basic optActiveColorMob cursor-pointer pb-2 text-[16px] border border-[#cac4c43f] border-t-0 border-x-0">
                      Properties
                    </div>
                  </Link>

                  <div
                    className="flex flex-col pb-2 text-[16px] border border-[#cac4c43f] border-t-0 border-x-0"
                    onClick={toggleHelp}
                  >
                    <div className="flex flex-row justify-between  optActiveColorMob cursor-pointer">
                      <div className=" text-[16px] font-basic ">Know More </div>
                      <div className="flex flex-col justify-center ">
                        {KnowMoreRightArrow ? (
                          <RiArrowRightSLine style={{ fontSize: "24px" }} />
                        ) : (
                          <FiChevronDown style={{ fontSize: "24px" }} />
                        )}
                      </div>
                    </div>
                    {helpOpen && (
                      <div className="flex flex-col text-sm  pl-2 gap-2">
                        {/* <div className=' text-start ml-4 mt-2 text-sm font-basic optActiveColorMob cursor-pointer'>Fractional Investment</div> */}
                        <a href="https://www.propftx.com/how-it-works/">
                          <div className=" text-start ml-4 mt-2  text-sm font-basic optActiveColorMob cursor-pointer">
                            How it works
                          </div>
                        </a>
                        <a href="http://13.200.51.241/blogs/">
                          <div className=" text-start ml-4 mt-2  text-sm font-basic optActiveColorMob cursor-pointer">
                            Blogs
                          </div>
                        </a>
                        <a href="https://www.propftx.com/faq/">
                          <div className=" text-start ml-4 mt-2  text-sm font-basic optActiveColorMob cursor-pointer">
                            FAQs
                          </div>
                        </a>

                        <div className="mt-2">
                          <div
                            className="flex gap-2 items-center text-base text-center rounded-md border  px-5 py-2 border-white"
                            style={{ width: "170px" }}
                          >
                            Watch Video{" "}
                            <AiOutlineYoutube style={{ fontSize: "20px" }} />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <a href="https://www.propftx.com/contact/">
                    <div className=" text-start  text-[16px] font-basic optActiveColorMob cursor-pointer">
                      Contact Us
                    </div>
                  </a>
                </div>

                {/* logout btn */}
                {props.isLoggedin && (
                  <div
                    onClick={handleLogout}
                    className="flex flex-row  gap-5  optActiveColorMob cursor-pointer logoutMobilebtn"
                  >
                    <div className={`flex flex-col justify-center `}>
                      <FaPowerOff />
                    </div>
                    <div className={` text-[16px] font-semibold `}>Logout</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;
