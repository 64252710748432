import React, { useEffect, useRef, useState } from 'react'
import CommonAccordianTab from './CommonAccordianTab'
import CustomInput from './Common/CustomInput'
import infoIcon from "../../Assets/icons/info.png"
import WhyToInvestAccordian from './WhyToInvestAccordian'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import { RiArrowUpFill } from 'react-icons/ri'
import netReturnIcon  from "../../Assets/icons/return.png"
import { ApiBaseUrl } from '../../Utils/urls'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { GiCardPick } from 'react-icons/gi'
import { calculateCapitalAppreciation, calculateNetprofit, calculateROI, calculateRentalIncome, calculateTotalInvestment } from '../../Utils/investmentCalculator'
import InfoPopup from '../InfoPopup/InfoPopup'
import ResaleStickyPriceComponent from './ResponsiveComponents/ResaleStickyPriceComp'
import { ClickOutside, DecrementToken, IncrementToken, ProceedToPurchase, calculateTotalPrice } from '../../Utils/tokenCalculation'
import { getUserDetails } from '../../Api/ProfileApi'
import WarningAlert from '../Alert/WarningAlert'
export default function WhyToInvest() {
  const {id}=useParams()
  const [investmentAmt,setinvestAmt]=useState(0)
 
  const [capitalApprec,setCapitalApprec]=useState(0)
  const [selectedPeriod, setSelectedPeriod] = useState(1);
  const [rentalYield,setRentalYield]=useState(0)
  const [netprofit,setNetprofit]=useState(0)
  const [additional,setAdditional]=useState(18255)
  const [extraCharge,setExtracharge]=useState(4895)
const [totalInvestment,setTotalInvesment]=useState(0)
 const [capitalAppAmt,setcapitalAppAmt]=useState(0)
const [rentalincome,setRentalIncome]=useState(0)
const [netReturn,setNetReturn]=useState(0)
const [roi, setROI] = useState(0);
  const user_id=localStorage.getItem("user_id")
  const accesstoken=localStorage.getItem("accestoken")
  const [tokenLimit,setTokenLimit]=useState(0)
  const [tokenCount, setTokenCount] = useState(1);
  const [tokenAmount,setTokenAmount]=useState(0) //price per share
  const [reachedTokenLimit, setReachedTokenLimit] = useState(false);

  const [showRentalPopup, setshowRentalPopup] = useState(false);

  const [showCapPopup, setshowCapPopup] = useState(false);

  const [showNetProfitPopup, setshowNetProfitPopup] = useState(false);
  const [showRentalIncomePop, setshowRentalIncomePop] = useState(false);
  const [showROIPopup, setshowROIPopup] = useState(false);

const navigate=useNavigate()


// token section
const location=useLocation()
const userId=localStorage.getItem("user_id")
const [propDetails,setpropDetails]=useState({}) //for sticky token
const {property_info,invoice_info}=propDetails
const [tokenCountSticky, setTokenCountSticky] = useState(1);
const [formattedPrice, setFormattedPrice] = useState(0);
const [platformFee,setPlatformFee]=useState(1)
const [gstFee,setGstFee]=useState(1)
const initialTotalFee=parseFloat(property_info?.price_per_share) + platformFee + gstFee
const [totalFee,setTotalFee]=useState(initialTotalFee)
const [reachedTokenLimitsticky, setReachedTokenLimitSticky] = useState(false);
const [isUserEmail,setUserEmail]=useState("")
const [isEmailUpdated,setEmailupdated]=useState(true)
const [isLoggedIn,setLoggedin]=useState(false)
  const [kycStatus,setKycStatus]=useState("")
const [showStickyPriceSection,setshowStickyPriceSection]=useState(false) 
  const priceSectionRef = useRef(null);
  const [isPropComingSoon,setPropComingSoon]=useState("")
  const [propertyCity, setpropertyCity] = useState("")
  const [propertyName, setpropertyName] = useState("")
  const [tokenLimitMob, settokenLimitMob] = useState(0);


  const fetchUserDetails=()=>{
    getUserDetails(userId,accesstoken,setLoggedin,setKycStatus,setUserEmail,navigate)
}

      
      useEffect(()=>{
        if(userId)
          {
              fetchUserDetails()
          }
      },[])



//token section end




//token section start

//ectract the element with property invoice_details
let invoiceDetails=invoice_info?.find(el=>el?.hasOwnProperty("invoice_details"))

//get the array
invoiceDetails=invoiceDetails?.invoice_details

//get the element with platformm handling fee name as an object
let phmArray=invoiceDetails?.find(el=>el.invoice_name.toLowerCase()==="Platform Handling fee".toLowerCase())
const phm_percentage=phmArray?.invoice_price


let gstArray=invoiceDetails?.find(el=>el.invoice_name.toLowerCase()==="gst".toLowerCase())
const gst_percentage=gstArray?.invoice_price

const price = property_info?.price_per_share ;
const tokenLimitSticky=property_info?.limit_per_share

const [buyPriceMob, setbuyPriceMob] = useState(0);


    
const handleToggleStickyPriceSection=()=>{
    setbuyPriceMob(propDetails.last_secondary_order_price);
    setshowStickyPriceSection(!showStickyPriceSection)
}


const handleClickOutside = (event) => {
ClickOutside(event,priceSectionRef,setshowStickyPriceSection)
};


const handleClosePushSection=()=>{
setshowStickyPriceSection(false)
}



useEffect(() => {
  // Add event listener when the component mounts
  document.addEventListener('mousedown', handleClickOutside);

  // Remove event listener when the component unmounts
  return () => {
      document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);

const handleIncrementSticky = () => {
IncrementToken(tokenCountSticky, setTokenCountSticky, tokenLimitSticky, setReachedTokenLimitSticky);
};


const handleDecrementSticky = () => {
DecrementToken(tokenCountSticky, setTokenCountSticky, setReachedTokenLimitSticky);
};

const PerTokenPrice = property_info?.price_per_share ;

useEffect(() => {
const { totalPrice, calculatedPlatformFee, gst_fee, total_fee } = calculateTotalPrice(PerTokenPrice, tokenCount, phm_percentage, gst_percentage);

setFormattedPrice(totalPrice.toLocaleString());
setPlatformFee(calculatedPlatformFee);
setGstFee(gst_fee);
setTotalFee(total_fee);
}, [tokenCountSticky, PerTokenPrice, phm_percentage, gst_percentage]);

// Calculate the total price
const totalPrice = price * tokenCountSticky;



const handleProceed = () => {

ProceedToPurchase(
  isLoggedIn,
  kycStatus,
  isUserEmail,
  navigate,
  tokenCountSticky,
  totalPrice,
  formattedPrice,
  property_info,
  gstFee,
  platformFee,
  totalFee,
  id,
  PerTokenPrice,
  setEmailupdated,
  location
)

}
//token section





  const handleShowRentalPopup = () => {
    setshowRentalPopup(true);
  };
  const handlecloseRentalPopup = () => {
    setshowRentalPopup(false);
  }


  const handleShowNetProfitPopup = () => {
    setshowNetProfitPopup(true);
  }
  const handleCloseNetProfitPopup = () => {
    setshowNetProfitPopup(false);
  }

  const handleShowCapAppPopup = () => {
    setshowCapPopup(true);
  }
  const handlecloseCapAppPopup = () => {
    setshowCapPopup(false);
  }
  const handleShowRentalIncomePopup = () => {
    setshowRentalIncomePop(true);
  }
  const handlecloseRentalIncomePopup = () => {
    setshowRentalIncomePop(false);
  }

  const handleShowROIPopup = () => {
    setshowROIPopup(true);
  }
  const handlecloseROIPopup = () => {
    setshowROIPopup(false)
  }

const calculateNetReturn = (selectedPeriod, tokenCount) => {
  const RentalIncome = calculateRentalIncome(selectedPeriod, investmentAmt, additional, extraCharge, rentalYield);
  const netProfit = calculateNetprofit(selectedPeriod, investmentAmt, capitalApprec, additional, extraCharge, rentalYield);

  const totalInvestment = calculateTotalInvestment(selectedPeriod, investmentAmt, additional, extraCharge);
  const netReturn = Number(investmentAmt)+ netProfit; 
  
  return netReturn;
};


const handleCalculate = (selectedPeriod) => {
  const totalinvest = calculateTotalInvestment(selectedPeriod,investmentAmt,additional,extraCharge)
  setTotalInvesment(totalinvest)
  const capitalAppreciation=calculateCapitalAppreciation(investmentAmt,capitalApprec,selectedPeriod,additional,extraCharge)
setcapitalAppAmt(capitalAppreciation)


  const RentalIncome=calculateRentalIncome(selectedPeriod,investmentAmt,additional,extraCharge,rentalYield)
setRentalIncome(RentalIncome)


const netProfit=calculateNetprofit(selectedPeriod,investmentAmt,capitalApprec,additional,extraCharge,rentalYield)

setNetprofit(netProfit)

const RoI=calculateROI(selectedPeriod,investmentAmt,capitalApprec,additional,extraCharge,rentalYield)
setROI(RoI)

const netreturn =calculateNetReturn(selectedPeriod,tokenCount)
setNetReturn(netreturn)

};

// console.log(selectedPeriod,totalInvestment, rentalYield,rentalincome,netprofit,roi,netReturn);

useEffect(() => {

  handleCalculate(selectedPeriod);
}, [selectedPeriod,netReturn, investmentAmt, capitalApprec, additional, extraCharge, rentalYield]);


  let  propertyDetailsURl=""
if(user_id)
{
  propertyDetailsURl=`${ApiBaseUrl}/fr-property/fetch-property-details?property_id=${id}&user_id=${user_id}`

}
else{
  propertyDetailsURl=`${ApiBaseUrl}/fr-property/fetch-property-details?property_id=${id}`
 
}

const fetchPropertyDetails=()=>{
  fetch(propertyDetailsURl, 
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': accesstoken 
    }
  
  }
  
  )
    .then(response => {
    
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
     
      return response.json();
    })
    .then(resdata => {
     
    // console.log('Response from server:', resdata);
     
        if(resdata.success)
        {
          setpropDetails(resdata.data)
         setinvestAmt(resdata.data.property_info.price_per_share)
         setCapitalApprec(resdata.data.property_info.capital_gain)
         setRentalYield(resdata.data.property_info.gross_yield)
         setTokenLimit(resdata.data.property_info.total_shares)
         setTokenAmount(resdata.data.property_info.price_per_share)
         setPropComingSoon(resdata.data.property_info.sale_status)
         setpropertyCity(resdata.data.property_info.property_city)
        setpropertyName(resdata.data.property_info.property_name.toLowerCase())
        settokenLimitMob(resdata.data.property_info.limit_per_share)
        }
  
    })
    .catch(error => {
     
      console.error('error details:', error);
    });
  
}
// Function to handle token increment
const handleIncrement = () => {
  if (tokenCount < tokenLimit) { // Check if token count is less than limit
    setTokenCount(prevCount => prevCount + 1); // Increment token count
  }
  if(tokenCount==tokenLimit)
  {
    setReachedTokenLimit(true)
  }
};

// Function to handle token decrement
const handleDecrement = () => {
  if (tokenCount > 1) { // Check if token count is greater than 1
    setReachedTokenLimit(false)
    setTokenCount(prevCount => prevCount - 1); // Decrement token count
  }
};



useEffect(()=>{
  const newInvestmentAmt = tokenCount * tokenAmount
  setinvestAmt(newInvestmentAmt);
},[tokenCount])





useEffect(()=>{
    fetchPropertyDetails()
},[])

const [showInfoIcon, setShowInfoIcon] = useState(false);
useEffect(() => {
  const handleResize = () => {
    setShowInfoIcon(window.innerWidth > 640);
  };

  // Set initial value
  handleResize();

  // Add event listener
  window.addEventListener('resize', handleResize);

  // Clean up event listener on component unmount
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);



  return (
<>
<style>
{`

  @media screen and (max-width: 640px) {
    .tooltipWhyInvest {
      display: none ;
    }
  
`}
</style>
{
    !isEmailUpdated && (
        <WarningAlert message="Please Update Your Email"/>
    )
}
<div class="sticky-button sm:hidden">
        <button  onClick={handleToggleStickyPriceSection} className='w-full bg-secondary text-white py-2.5 px-4 rounded-md font-semibold text-base'>
            Buy Now
        </button>
    </div>

    {
    showStickyPriceSection && 
    (
  
      <ResaleStickyPriceComponent
      id={id}
      propertyCity={propertyCity}
      propertyName={propertyName}
      buyPriceMob={buyPriceMob}
      tokenLimitMob={tokenLimitMob}
      handleClosePushSection={handleClosePushSection}
      handleProceed={handleProceed}
      priceSectionRef={priceSectionRef}
      showStickyPriceSection={showStickyPriceSection}
      />
    )
}





    <div>
<WhyToInvestAccordian title="Investment Calculator :" showInfoIcon={showInfoIcon}>



<div className='flex  flex-col sm:flex-row justify-between'>

  {/* calculation-section */}
  <div className='flex flex-col gap-6 sm:gap-8 calculateSection'>


  <div className='flex items-center sm:items-start  sm:flex-col gap-3'>

  <p className='text-gray text-xs w-1/2 sm:w-full'>Select Number of Tokens</p>
    <div className='flex tokenDiv  w-1/2 sm:w-full'>
          <button className={`incBtn bg-secondary text-white  text-lg`} onClick={handleDecrement}> -</button>
          <input type="text" value={tokenCount} className='tokenInput text-base text-black text-center' />
          {/* <button  className={`decBtn  ${reachedTokenLimit?'bg-gray':'bg-secondary'}`} onClick={handleIncrement} disabled={reachedTokenLimit}> +</button> */}
          <button  className="decBtn bg-secondary text-white text-lg" onClick={handleIncrement} disabled={reachedTokenLimit}> +</button>
    </div>
{
  reachedTokenLimit &&(
    <p className='text-xs text-red text-center'>You can select maximum {tokenLimit} Tokens</p>

  )
}
    </div>

      <div className='flex flex-col gap-4'>
          <div className='flex items-center sm:items-start  sm:flex-col gap-2'>
            <p className='text-gray text-xs  w-1/2 sm:w-full'>Investment Amount</p>
            <CustomInput label="Capital Appreciation" symbol="₹" 
            value={investmentAmt && Number(investmentAmt).toLocaleString('en-IN')}
           disabled={true}
            onChange={(e) => {
              const inputValue = e.target.value;
              // Remove commas from the input value before setting state
              const numericValue = parseFloat(inputValue.replace(/,/g, ''));
              if (!isNaN(numericValue)) {
                // Only set the state if the input value is a valid number
                setinvestAmt(numericValue);
              }
            }}


           

            />
          </div>

          <div className='flex flex-col gap-2'>

            <div className='flex gap-1 items-center'>
                  <p className='text-gray text-xs'>Period of Investment (Month)</p>
                 
               
            </div>
      
 <div className='flex flex-col gap-2'>
 
       <input type="range" 
       min="1"  max="40" name='percentage_of_investment' className='nomineeRangeSlider'
       value={selectedPeriod}
       onChange={(e) => {
        const value = e.target.value;
       
        setSelectedPeriod(Number(value));
    }}
                       />
                       <p className='text-sm text-blue'>{selectedPeriod && selectedPeriod}</p>




</div>




          </div>

      </div>





      <div className='flex justify-between'>
          <div className='flex flex-col gap-2 text-center'>
              <div className='flex gap-1 items-center'>

                 <p className='text-sm text-gray font-semibold'>Rental Yield</p>

                 <div className="tooltipWhyInvest">
                          
                          <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} />
                              <span className="tooltipWhyInvesttextBottom">

                                <p className='text-blue text-sm'>What is Rental Yield?</p>
                                <p className='text-gray text-sm'>
                                The percentage of the value of a property that an investor earns from rentals each year.


                            </p>
                              </span>
                          </div>


<IoMdInformationCircleOutline className='block sm:hidden' style={{fontSize:"14px",color:" #D328A7"}} 
                          onClick={handleShowRentalPopup} />

{

showRentalPopup && (
        <>
         
                        <InfoPopup>
                            <div className='p-2'>
                            <p className='text-blue text-left text-sm'>What is Rental Yield?</p>
                                <p className='text-gray text-left text-sm'>
                                The percentage of the value of a property that an investor earns from rentals each year.
                            </p>
                            </div>
           
                            <button onClick={handlecloseRentalPopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                Ok</button>
                            
                            </InfoPopup>
        </>
    )
}


              </div>
              <p className='text-secondary text-base font-semibold'>{rentalYield && rentalYield} %</p>
          </div>

          <div className='flex flex-col gap-2 text-center'>
              <div className='flex gap-1 items-center'>

                 <p className='text-sm text-gray font-semibold'>Capital Appreciation</p>

                
                    <div className="tooltipWhyInvest">
                          
                          <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} />
                              <span className="tooltipWhyInvesttextBottom">

                                <p className='text-blue text-sm'>What is Capital Appreciation?</p>
                                <p className='text-gray text-sm'>
                                Capital appreciation refers to the increase in the value of a property over time.


                            </p>
                              </span>
                          </div>



                          <IoMdInformationCircleOutline className='block sm:hidden' style={{fontSize:"14px",color:" #D328A7"}} 
                          onClick={handleShowCapAppPopup} />

{

showCapPopup && (
        <>
         
                        <InfoPopup>
                            <div className='p-2'>
                            <p className='text-blue text-left text-sm'>What is Capital Appreciation?</p>
                                <p className='text-gray text-left text-sm'> 
                                Capital appreciation refers to the increase in the value of a property over time.
                            </p>
                            </div>
           
                            <button onClick={handlecloseCapAppPopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                Ok</button>
                            
                            </InfoPopup>
        </>
    )
}


              </div>
              <p className='text-secondary text-base font-semibold'>{capitalApprec && capitalApprec} %</p>
          </div>

      </div>



      <div className='w-full'>
        {/* <button  onClick={()=>handleCalculate(selectedPeriod)} style={{width:"100%"}} className='bg-secondary py-2 text-white text-base font-semibold rounded-md calculate-btn'>
          Calculate
          </button> */}
      </div>


  </div>

{/* result-section */}
<div className='resultSection flex flex-col gap-3 sm:gap-12'>

    <div className='flex gap-4  px-1 py-2 sm:p-4  sm:mt-0  justify-between shadowBox'>
        <div className='flex gap-2 items-center'>
            <div>
             <img src={netReturnIcon} alt="" />
            </div>
            <p className='text-sm sm:text-2xl font-bold text-secondary'>Token Value</p>
        </div>

        <div className='flex gap-2  items-center'>
        <RiArrowUpFill  className='increaseIcon' />
            <p className='text-base sm:text-2xl font-bold text-secondary'>₹ {netReturn && netReturn.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
        </div>
    </div>

    <div>

        <div className='flex flex-col gap-2 resultBox'>

            <div className='flex justify-between'>

              <div className='flex gap-1 items-center'>
                    <p className='text-gray text-base'>Rental Income </p>
                    <div className="tooltipWhyInvest">
                            
                            <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} />
                                <span className="tooltipWhyInvesttextLeft">
                                <p className='text-blue text-sm'>What is Rental Income?</p>
                                <p className='text-gray text-sm'>
                                It represents the rental income generated by the tokens acquired by the investor.

                            </p>
                                </span>
                            </div>

 <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} className='block sm:hidden' onClick={handleShowRentalIncomePopup} />

{showRentalIncomePop && (
       <InfoPopup>
             <div className='p-2'>
                 <p className='text-blue text-left text-sm'>What is Rental Income?</p>
                    <p className='text-gray text-left text-sm'> 
                    It represents the rental income generated by the tokens acquired by the investor.
                     </p>
                </div>
            <button onClick={handlecloseRentalIncomePopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                Ok</button>
                            
                            </InfoPopup>
        
    )
}
    


                            :
                
              </div>
              <p className='text-base font-bold text-black'>₹ {rentalincome && 
              
              rentalincome.toLocaleString()}</p>

            </div>


            <div className='flex justify-between'>

              <div className='flex gap-1 items-center'>
                    <p className='text-gray text-base'>Net Profit</p>
                    <div className="tooltipWhyInvest">
                            
                            <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} />
                                <span className="tooltipWhyInvesttextLeft">

                                <p className='text-blue text-sm'>What is Net profit?</p>
                                <p className='text-gray text-sm'>
                                Amount received after subtracting all the expenses from total revenue.

                            </p>
                                </span>
                            </div>

<IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} className='block sm:hidden' onClick={handleShowNetProfitPopup} />

{showNetProfitPopup && (
       <InfoPopup>
             <div className='p-2'>
                 <p className='text-blue text-left text-sm'>What is Net profit?</p>
                    <p className='text-gray text-left text-sm'>  
                    Amount received after subtracting all the expenses from total revenue.
                     </p>
                </div>
            <button onClick={handleCloseNetProfitPopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                Ok</button>
                            
                            </InfoPopup>
        
    )
}




                            :
                
              </div>
              <p className='text-base font-bold text-black'>₹ {netprofit &&
              netprofit.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
              
             }</p>

            </div>



            <div className='flex justify-between'>

                    <div className='flex gap-1 items-center'>
                          <p className='text-gray text-base'>ROI %</p>
                          <div className="tooltipWhyInvest">
                                  
                                  <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} />
                                      <span className="tooltipWhyInvesttextLeft">
                                      <p className='text-blue text-sm'>What is ROI?</p>
                                <p className='text-gray text-sm'>
                                Profitability of an investment as determined by comparing the gain or loss to the investment cost.

                            </p>

                                      </span>
                                  </div>

   <IoMdInformationCircleOutline style={{fontSize:"14px",color:" #D328A7"}} className='block sm:hidden' onClick={handleShowROIPopup} />

{showROIPopup && (
       <InfoPopup>
             <div className='p-2'>
                 <p className='text-blue text-left text-sm'>What is ROI?</p>
                    <p className='text-gray text-left text-sm'>  
                    Profitability of an investment as determined by comparing 
                    the gain or loss to the investment cost.
                     </p>
                </div>
            <button onClick={handlecloseROIPopup} className='bg-secondary w-full rounded-md py-2 font-bold text-sm text-white'>
                                Ok</button>
                            
                            </InfoPopup>
        
    )
}

                        


                                  :
                      
                    </div>  
                    <p className='text-base font-bold text-black'>
                        {roi && roi.toFixed(2)}%
                      
                      </p>
            </div>


        </div>

    </div>

</div>



</div>

</WhyToInvestAccordian>


    </div>


    </>
  )
}
