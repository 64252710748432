import React from "react";
import { Doughnut } from "react-chartjs-2";

export default function ChartSection({
  data,
  options,
  investmentAmount,
  timePeriod,
  estRoi,
  totalReturn
}) {
  return (
    <div className="flex md:flex-row  flex-col gap-2.5">
      <div className="md:w-[40%] w-full">
        <Doughnut data={data} options={options} />
      </div>

      <div className="flex justify-center items-center md:w-[60%]">
        <div className="flex flex-col gap-2.5 w-[80%]">
          <div className="flex justify-between text-sm">
            <p className="font-bold">Amount to Invest</p>
            <p className="">
              ₹{" "}
              {investmentAmount < 5000
                ? 5000
                : investmentAmount.toLocaleString()}
            </p>
          </div>

          <div className="flex justify-between text-sm">
            <p className="font-bold">Expected ROI in {timePeriod} years</p>
            <p className="">{estRoi.toFixed(2)} %</p>
          </div>

          <div className="flex justify-between text-sm">
            <p className="font-bold">Estd. Returns</p>

            <p className="">
              ₹{" "}
              {Number(totalReturn).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
