import React, { useEffect, useState } from 'react'
import { FaLocationDot } from 'react-icons/fa6'
import "./transaction-style.css"
import { TfiFilter } from 'react-icons/tfi'
import TransactionFilter from '../TransactionFilter/Index'
import { ApiBaseUrl } from '../../Utils/urls'
import TransactionRow from './TransactionRow'
import NoTransaction from './NoTransaction'
import LoaderRelative from '../Loader/LoaderRelative'
import TransactionFilterMobile from '../TransactionFilterMobile/Index'
import { getAllInitialAllProp } from '../../Api/BuyingFilter'
import { useNavigate } from 'react-router-dom'
import { handlelogout } from '../../Utils/logout'

export default function Transaction() {
const [activeTab,setactiveTab]=useState("buy")
const [isOpenMob, setIsOpenMob] = useState(false);
  const togglePopup = () => {
    setIsOpenMob(!isOpenMob);
  };


  //date filter states
  const getCurrentFormattedDate = (date) => {
    const pad = (num) => String(num).padStart(2, '0');
    return `${date.getUTCFullYear()}-${pad(date.getUTCMonth() + 1)}-${pad(date.getUTCDate())} ${pad(date.getUTCHours())}:${pad(date.getUTCMinutes())}:${pad(date.getUTCSeconds())}`;
  };
  

  let today = new Date();
  let sixMonthsAgo = new Date(today);
  sixMonthsAgo.setMonth(today.getMonth() - 6); 
  
  
  
  const [fromDate,setFromDate]=useState(getCurrentFormattedDate(sixMonthsAgo))
  const [toDate,setToDate]=useState(getCurrentFormattedDate(today))
//  


  const userId = localStorage.getItem('user_id');
  const accesstoken=localStorage.getItem("accestoken")
  const [isBuyingLoading,setBuyingLoading]=useState(false)
  const [BoughtResultData, setBuyingResultData] = useState([]);
  const navigate=useNavigate()

  const currentDate = new Date();
  const sixtyDaysAgo = new Date(currentDate);
  sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);
  sixtyDaysAgo.setHours(sixtyDaysAgo.getHours() + 5);
  sixtyDaysAgo.setMinutes(sixtyDaysAgo.getMinutes() + 30);
  const fromDateString = sixtyDaysAgo.toISOString().slice(0, 19).replace('T', ' ');
  
  currentDate.setHours(currentDate.getHours() + 5); 
  currentDate.setMinutes(currentDate.getMinutes() + 30); 
  const toDateString = currentDate.toISOString().slice(0, 19).replace('T', ' ');

  const [showBuyingMobFilter,setShowBuyingMobFilter]=useState(false)
 
  const [UsersAllBoughtProp,setUsersAllBoughtProp]=useState([])

  const [boughtfilters, setBuyingFilters] = useState({
    user_id: userId,
    filtersByString: {
      sale_type: null 
  },
   filterByStatus:[],
 
  purchased_date: {
      "from": fromDate,
      "to": toDate
  }, 
  filtersByPlaces: [],
 
  filtersByBuilders: [],
  filtersByPrice:{token_price: null}
  
  });

  const handleApplyFilter=()=>{
    setBuyingLoading(true)
    fetch(`${ApiBaseUrl}/fr-user/get-user-purchase-transactions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accesstoken 
      },
      body: JSON.stringify({
        ...boughtfilters,
        user_id: userId,
        purchased_date: {
          "from": fromDate,
          "to": toDate 
      }, 
       
      }),
    })
    .then(response => {
      // if (!response.ok) {
      //   throw new Error('Failed to fetch result');
      // }
      return response.json();
    })
    .then(res => {
     
      if (!res.success && res.data === 'INVALID TOKEN OR USER ID MISMATCH') {
           
        handlelogout(navigate); 
      }
      setBuyingLoading(false)
      if((Array.isArray(res.data))){
        setBuyingResultData(res.data);
      }
      

    })
    .catch(error => {
      console.error('Error fetching result:', error);
    });
  }
  useEffect(()=>{
    if(userId)
    {
      handleApplyFilter()
    }
   
  },[activeTab==='buy',userId,fromDate,toDate])

  useEffect(()=>{
    getAllInitialAllProp(accesstoken,boughtfilters,setUsersAllBoughtProp)
  },[])

 

const handleShowBuyingMobFilter=()=>{
  setShowBuyingMobFilter(true)
}


 
  return (
    <>
    <div className='px-4 text-base font-medium text-left font-nunito hidden sm:block'>Transactions</div>

{/* mobile */}
<div className='flex w-full  sm:hidden'>
    <div className='transTitle'>
        Transactions
    </div>
    {/* filter icon for mobile */}



      




{/* mobile filter icon */}

    {
      activeTab==="buy" && UsersAllBoughtProp && UsersAllBoughtProp.length!=0 && (
<div className='filterIconsec' style={{zIndex: showBuyingMobFilter ? '0' : '999'}}>
    <TfiFilter style={{ height: "35px" }} onClick={handleShowBuyingMobFilter} className='cursor-pointer' />
    </div>
      )
    }


</div>

    <div className='flex justify-between items-center' >
    <div className='flex py-4 px-4 gap-4 transactabScroll' style={{zIndex: showBuyingMobFilter ? '0' : '999'}}>
        <button  onClick={()=>setactiveTab("buy")} 
         className={`px-4 py-2.5 text-xs sm:text-sm  transacMobiTabBtn rounded-lg border ${activeTab==="buy"?'bg-secondary text-white':'border-lightgray'} `}>
            Buying Transactions
        </button>

        <button onClick={()=>setactiveTab("sell")} 
        className={`px-4 py-2.5 text-xs sm:text-sm  transacMobiTabBtn rounded-lg border ${activeTab==="sell"?'bg-secondary text-white':'border-lightgray'} `}>
        Selling Transactions
        </button>

        <button onClick={()=>setactiveTab("rental")} 
        className={`px-4 py-2.5 text-xs sm:text-sm transacMobiTabBtn rounded-lg border ${activeTab==="rental"?'bg-secondary text-white':'border-lightgray'} `}>
        Rental Transaction
        </button>

    </div>
    
    {/* {
      clearAll &&(
        <div>Clear</div>
      )
    } */}
    

<div className='md:flex hidden items-center gap-2 '>

 

    {
      activeTab==="buy" && UsersAllBoughtProp && UsersAllBoughtProp.length!=0 && (
<div className='mr-[21px] hidden sm:block cursor-pointer' onClick={togglePopup}><TfiFilter style={{ height: "35px" }} /></div>
   
      )
    }

</div>

{/* {
      activeTab==="sell" &&(
<div className='mr-[21px] cursor-pointer' ><TfiFilter style={{ height: "35px" }} /></div>
   
      )
    } */}
     </div>
 
    <div className={ `rounded-xl popup-containerBookedFilter TransactionWebFilterZIndex  ${isOpenMob ? 'active' : ''}`} style={{ boxShadow: '0px 4px 30px 0px #00000026' }}>
       <TransactionFilter
        isOpenMob={isOpenMob} 
        setIsOpenMob={setIsOpenMob} 
        handleApplyFilter={handleApplyFilter}
        setBuyingFilters={setBuyingFilters}
        boughtfilters={boughtfilters}
        toDate={toDate}
        fromDate={fromDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        getCurrentFormattedDate={getCurrentFormattedDate}

        // setClearAll={setClearAll}
        />

      </div>


{/* {

showBuyingMobFilter && (
  <div className="fixed  sm:hidden inset-0 z-50 flex items-center justify-center">
  <div className="h-full w-full">
  <TransactionFilterMobile
  setShowBuyingMobFilter={setShowBuyingMobFilter}
  setBuyingFilters={setBuyingFilters}
  handleApplyFilter={handleApplyFilter}
  boughtfilters={boughtfilters}
  /> 
  
    </div>
    </div>
    
   
)


} */}




<div className="fixed  sm:hidden inset-0 z-50 flex items-center justify-center">
  <div className="h-full w-full">
  <TransactionFilterMobile
  setShowBuyingMobFilter={setShowBuyingMobFilter}
  setBuyingFilters={setBuyingFilters}
  handleApplyFilter={handleApplyFilter}
  boughtfilters={boughtfilters}
  showBuyingMobFilter={showBuyingMobFilter}
  toDate={toDate}
        fromDate={fromDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        getCurrentFormattedDate={getCurrentFormattedDate}
  /> 
  
    </div>
    </div>
     

    {/* flex flex-col py-4 px-4 gap-4 */}
    {/* Buying Transactions section*/}
    {
        activeTab==="buy" &&(
            <div className='flex flex-col gap-2 ' >

         
{isBuyingLoading ? (
       <LoaderRelative/>
      ) : BoughtResultData && BoughtResultData.length=== 0 ? (
        <div className='my-7'>
            <NoTransaction/>
        </div>

       
      ) : (
        BoughtResultData && BoughtResultData.map((item, index) => (
          <div key={index} style={{zIndex: showBuyingMobFilter ? '0' : '999'}}>
            <TransactionRow  {...item} index={index}/>
          </div>
        ))
      )}
    
     
    
        </div>
        )
    }
   
    {/* Selling Transactions section */}
   {
        activeTab==="sell" &&(
            <div className='noTransContainer'>
            <hr className='hrTransac'/>
            
            <div className='noTransactionImg'></div>
            <p className='noTransactionText text-gray'>No transaction has been recorded yet</p>
            
            </div>
        )
   }


  {/* Rental Transactions section */}
  {
        activeTab==="rental" &&(
            <div className='noTransContainer'>
            <hr className='hrTransac'/>
            
            <div className='noTransactionImg'></div>
            <p className='noTransactionText text-gray'>No transaction has been recorded yet</p>
            
            </div>
        )
   }
   
  
    </>
    
  )
}
