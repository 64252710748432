import React from 'react'
import { useEffect,useMemo } from 'react';
import { useState } from 'react';
import './Index.css'
import { useBookedPropertyFilters } from '../../Utils/bookedPropFilter';
import { FaCaretDown } from "react-icons/fa";
import { FaCaretRight } from "react-icons/fa";
import { ApiBaseUrl } from '../../Utils/urls';
import { initialPropertyFiltersBooked } from '../../Utils/bookedPropFilter';
import { IoMdArrowBack } from 'react-icons/io';




function CityButton({ city, onClick, selectedCities }) {
  const isClicked = (selectedCities || []).includes(city.city_name);

  const handleClick = () => {
    onClick(city.city_name);
  };

  return (
    <button
      className={`cityButton text-black text-sm h-8 pl-2 pr-2 ${isClicked ? 'clickedButton' : ''}`}
      onClick={handleClick}
    >
      {city.city_name}
    </button>
  );
}

  
function BuilderButton({ builder, onClick, selectedBuilders }) {
  if (!builder) {
    return null;
  }
  const isClicked = selectedBuilders.includes(builder.builder_name);
  const handleClick = () => {
    onClick(builder.builder_name);
  };

  return (
    <button
      className={`BuilderButton text-black text-sm h-8 pl-2 pr-2 ${isClicked ? 'clickedButton' : ''}`}
      onClick={handleClick}
    >
      {builder.builder_name}
    </button>
  );
}

// function AssetButton({ name, onClick, assetType }) {
//   const isClicked = assetType.includes(name);

//   const handleClick = () => {
//     onClick(name);
//   };
//     return (
//       <div className="flex flex-row gap-4 w-full" onClick={handleClick}>
//         <div className={`checkBox  text-black ${isClicked ? 'checked' : ''}`} />
//         <button
//           className=" text-black text-sm font-normal"
//         >
//           {name}
//         </button>
//       </div>
//     );
//   }
  
  // function  PriceRangeButton({ name, onClick, priceRange }) {
  //     const isClicked = priceRange.includes(name);
    
  //     const handleClick = () => {
  //       onClick(name);
  //     };
    
  //     return (
  //       <div className="flex flex-row gap-4 w-full" onClick={handleClick}>
  //         <div className={`checkBox  text-black ${isClicked ? 'checked' : ''}`} />
  //         <button
  //           className=" text-black text-sm font-normal"
  //         >
  //           {name}
  //         </button>
  //       </div>
  //     );
  //   }




  function PriceRangeButton({ name, onClick, priceRange }) {
    const isClicked = priceRange[name];
  
    const handleClick = () => {
      onClick(name);
      
    };

   
  
    return (
      <div className="flex flex-row   text-sm font-normal items-center gap-2 w-full" onClick={handleClick}>
        <div className={`priceRangeRadio text-black ${isClicked ? 'priceRangeselected' : ''}`} />
        <input type='radio' name='price' className="text-black text-sm font-normal" style={{display:'none'}} />
        {name}
      </div>
    );
  }


    
function TransactionFilterMobile(props) {

  const accesstoken=localStorage.getItem("accestoken")


 
 
  const [currencyFilters, setCurrencyFilters] = useState([]);
  const [isOpenOptionProp, setIsOpenOptionsProp] = useState(false);
  const [isOpenOptionsSales, setIsOpenOptionsSales] = useState(false);
  const [isOpenOptionsCity, setIsOpenOptionsCity] = useState(false);
  const [isOpenOptionsBuilder, setIsOpenOptionsBuilder] = useState(false);
  const [isOpenOptionsPrice, setIsOpenOptionsPrice] = useState(false);
  const [isOpenOptionsDate, setIsOpenOptionsDate] = useState(false);
  


  
  const currentDate = new Date();
  const sixtyDaysAgo = new Date(currentDate);
  sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);
  sixtyDaysAgo.setHours(sixtyDaysAgo.getHours() + 5);
  sixtyDaysAgo.setMinutes(sixtyDaysAgo.getMinutes() + 30);
  const fromDateString = sixtyDaysAgo.toISOString().slice(0, 19).replace('T', ' ');
  
  currentDate.setHours(currentDate.getHours() + 5); 
  currentDate.setMinutes(currentDate.getMinutes() + 30); 
  const toDateString = currentDate.toISOString().slice(0, 19).replace('T', ' ');



  const toggleOptionsProp = () => {
    setIsOpenOptionsProp(prevState => !prevState);
  };
  const toggleOptionsSales = () => {
    setIsOpenOptionsSales(prevState => !prevState);
  };
  const toggleOptionsCity = () => {
    setIsOpenOptionsCity(prevState => !prevState);
  };
  const toggleOptionsBuilder = () => {
    setIsOpenOptionsBuilder(prevState => !prevState);
  };
  const toggleOptionsPrice = () => {
    setIsOpenOptionsPrice(prevState => !prevState);
  
  };
 

  const toggleOptionsDates = () => {
    setIsOpenOptionsDate(prevState => !prevState);
  };



const {setShowBuyingMobFilter,setBuyingFilters
  ,handleApplyFilter
  ,showBuyingMobFilter,
  boughtfilters,
  toDate,
  setFromDate,
  setToDate,
  getCurrentFormattedDate,
  fromDate

}=props
  

const [saleTypes, setSaleTypes] = useState([]);
const [transactionType,setTransactionType]=useState([])
const [placeFilters, setPlaceFilters] = useState([]);
const [priceRange, setPriceRange] = useState({});

const [BuilderClicked, setBuilderClicked] = useState(false)
const [builderFilter,setBuilderFilter]=useState([])


const [selectedBuilders, setSelectedBuilders] = useState([]);
const [selectedCities, setSelectedCities] = useState([]);
const [cityClicked, setcityClicked] = useState(false);

  const [isLoggedin, setisLoggedin] = useState(false); 
  const [userId, setUserId] = useState(null);
  useEffect(() => {
    const userIdFromLocalStorage = localStorage.getItem('user_id');
    const sessionLSFromLocalStorage = localStorage.getItem('sessionLS');
    
    if (userIdFromLocalStorage && sessionLSFromLocalStorage) {
      setisLoggedin(true);
      setUserId(userIdFromLocalStorage);
    }
  }, []);
  
 
  const initialBookingFilter={
    user_id: userId,
    filtersByString: {
        sale_type: null 
    },
     filterByStatus:[],
    
    purchased_date: {
        "from": fromDateString,
          "to": toDateString
    }, 
    filtersByPrice:{token_price: null},
  
    filtersByPlaces: [],
   
    filtersByBuilders: []
   
  }

  const fetchFilterMobile = async () => {
    fetch(`${ApiBaseUrl}/fr-user/get-user-purchase-transactions`, 
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': accesstoken 
        },
        body: JSON.stringify(initialBookingFilter)
      }
      
      )
        .then(response => {
        
          
         
          return response.json();
        })
        .then(data => {
       
         
         // console.log('Response from server:', data);
          if(data.success)
          {
         //  console.log(data)
           setBuilderFilter(data.filters.builder_filters)
           setPlaceFilters(data.filters.place_filters)
           
          }
    
          
        })
        .catch(error => {
         
          //console.error('error:', error);
         
        });
    
  };
  
  
  useEffect(()=>{
    if(userId){
      fetchFilterMobile()
    }
    
  },[userId])
  
  


//salestype filters
const handleSaleTypeClick = (saleType) => {
  let updatedSaleTypes;
  if (saleTypes.includes(saleType)) {
    updatedSaleTypes = saleTypes.filter((type) => type !== saleType);
  } else {
    updatedSaleTypes = [...saleTypes, saleType];
  }

  setSaleTypes(updatedSaleTypes);


  // Update property_filters with the new sale types
  let updatedPropertySaleTypes;
  if (updatedSaleTypes.length === 0) {
    updatedPropertySaleTypes = null;
  } else if (updatedSaleTypes.includes('all')) {
    updatedPropertySaleTypes = ['Public Sale', 'Resale','Presale'];
  } else {
    updatedPropertySaleTypes = updatedSaleTypes;
  }

  setBuyingFilters((prevFilters) => ({
    ...prevFilters,
    filtersByString: {
      ...prevFilters.filtersByString,
      sale_type: updatedPropertySaleTypes,
    },
  }));
  
};
//transaction type
const handleTransactionTypeClick = (transType) => {
  let updatedTransactionTypes;
  if (transactionType.includes(transType)) {
    updatedTransactionTypes = transactionType.filter((type) => type !== transType);
  } else {
    updatedTransactionTypes = [...transactionType, transType];
  }

  setTransactionType(updatedTransactionTypes);


  // Update property_filters with the new sale types
  let updatedPropTransactionTypes;
  if (updatedTransactionTypes.length === 0) {
    updatedPropTransactionTypes = [];
  } else if (updatedTransactionTypes.includes('all')) {
    updatedPropTransactionTypes = ["COMPLETED","PENDING","CANCEL"];
  } else {
    updatedPropTransactionTypes = updatedTransactionTypes;
  }

  setBuyingFilters((prevFilters) => ({
    ...prevFilters,
    filterByStatus:updatedPropTransactionTypes
  }));

  
};
// city select
const handleCityClick = (cityName) => {
  let updatedCities;
  if (selectedCities.includes(cityName)) {
      updatedCities = selectedCities.filter((city) => city !== cityName);
  } else {
      updatedCities = [...selectedCities, cityName];
  }

  setSelectedCities(updatedCities);
  const updatedFiltersByPlaces = updatedCities.map((cityName) => {
      const selectedCity = placeFilters.find(city => city.city_name === cityName);
      return { city_id: selectedCity.city_id, locations: []  };
  });


  const updatedBookedPropertyFilters = { ...boughtfilters, filtersByPlaces: updatedFiltersByPlaces };
  setBuyingFilters(updatedBookedPropertyFilters);

  //setIsFilterActive(true);
};

//builder filter 
const handleBuilderClick = (builderName) => {
  let updatedBuilders;

  if (selectedBuilders.includes(builderName)) {
      updatedBuilders = selectedBuilders.filter((builder) => builder !== builderName);
  } else {
      updatedBuilders = [...selectedBuilders, builderName];
  }

  setSelectedBuilders(updatedBuilders);

  const updatedFiltersByBuilders = updatedBuilders.map((builderName) => {
      const selectedBuilder = builderFilter.find(builder => builder.builder_name === builderName);
      return selectedBuilder ? selectedBuilder.builder_id : null;
  }).filter(id => id !== null);
  const updatedBuyingPropertyFilters = { ...boughtfilters, filtersByBuilders: updatedFiltersByBuilders };
  setBuyingFilters(updatedBuyingPropertyFilters);


  //setIsFilterActive(true);
};

//price filter
const handlePriceRangeClick = (name) => {
  // Update the state with the new price range name
  setPriceRange(prevPriceRange => {
    const updatedPriceRange = { ...prevPriceRange };
    Object.keys(updatedPriceRange).forEach(key => {
      updatedPriceRange[key] = false; // Deselect all other options
    });
    updatedPriceRange[name] = true; // Select the clicked option
    return updatedPriceRange;
  });
  // Update property_filters with the new price range
  let filtersByPrice = {};

  if (name === "Upto 50000") {
    filtersByPrice = { from: "0", to: "50000" };
  } else if (name === "50,000-1,00,000") {
    filtersByPrice = { from: "50000", to: "100000" };
  } else if (name === "Above 1,00,000") {
    filtersByPrice = { from: "100000", to: "10000000" };
  } else {
    // If no price range is selected, set to null
    filtersByPrice = null;
  }

  setBuyingFilters((prevFilters) => ({
    ...prevFilters,
    filtersByPrice: { token_price: filtersByPrice },
  }));
};

const ApplyFilter=()=>{
   handleApplyFilter()
  setShowBuyingMobFilter(false)


}
//clear all
const handleAllClearClick = () => {
 
  setSaleTypes([]);
  setSelectedCities([]);
  setSelectedBuilders([]);
  setTransactionType([])
  setPriceRange({});
  //setPriceRange([]);
  // setStartDate('');
  // setEndDate('');
  //setIsFilterActive(false);
  //setIsClearButtonActive(false);

  setBuyingFilters(initialBookingFilter)

};



  return (
    <>
  <div className={`hideMobFilter  ${showBuyingMobFilter?'mobblock':'mobhidden'} flex flex-col justify-between bg-white`} style={{height:"95%",position:'fixed',width:'100%'}}>

    <div>
  <div className=' text-black pt-4 flex flex-col py-2 px-4' >

  <div className='flex flex-row justify-between py-2 mt-10'>
    <div className='flex gap-2 justify-center items-center'>
        <IoMdArrowBack  onClick={()=>setShowBuyingMobFilter(false)} className='cursor-pointer'/>
        <div className='  text-base font-bold ' >Filter</div>
    </div>

  
    <div className={`mt-2  text-[14px] ${
    // Check if all filters are cleared
    !transactionType.length && !saleTypes.length && !selectedCities.length && !selectedBuilders.length   ? 'ButtonBeforeCollect' : 'ButtonCollect'
  } font-normal cursor-pointer px-2 py-1 rounded-md `} onClick={handleAllClearClick}>Clear All</div>
  </div>


  <div className='flex flex-row justify-between' onClick={toggleOptionsSales}>
  <div className='text-base font-medium text-start'>Sales Type</div>
  
  <div className='flex flex-col justify-center'>
   {isOpenOptionsSales ? <FaCaretRight /> : <FaCaretDown />}
  </div>
</div>


<div>
    {isOpenOptionsSales && (
      <div className='openOptions'>
        <div className='flex flex-row flex-wrap gap-2 p-2 -ml-2'>
          <button
            className={`propertyPhasesButton ${
              saleTypes.includes('all') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
            } text-black text-xs w-20 h-8`}
            onClick={() => handleSaleTypeClick('all')}
          >
            All
          </button> 
          <button
            className={`propertyPhasesButton ${
              saleTypes.includes('Presale') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
            } text-black text-xs w-16 h-8 `}
            onClick={() => handleSaleTypeClick('Presale')}
          >
            Pre Sale
          </button>
          <button
            className={`propertyPhasesButton ${
              saleTypes.includes('Public Sale') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
            } text-black text-xs w-auto px-1 h-8 `}
            onClick={() => handleSaleTypeClick('Public Sale')}
          >
            Public Sale
          </button>
          <button
          className={`propertyPhasesButton ${
            saleTypes.includes('Resale') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
          } text-black text-xs w-20 h-8 `}
          onClick={() => handleSaleTypeClick('Resale')}
        >
          Resale
        </button>
        </div>
      </div>
    )}
    </div>



  </div>



  <div className='flex flex-row justify-center mt-1'>
    <hr style={{ color: "rgba(217, 217, 217, 1)",width:"100%" }}></hr>
  </div>




    <div className='flex flex-col justify-between px-4 py-2'>
    
    <div className='flex flex-row justify-between' onClick={toggleOptionsProp}>
        <div className='text-base font-medium text-start'>Transaction Type</div>
        
        <div className='flex flex-col justify-center'>
         {isOpenOptionProp ? <FaCaretRight /> : <FaCaretDown />}
        </div>
    </div>

    <div>
    {isOpenOptionProp && (
      <div className='openOptions'>
        <div className='flex flex-row flex-wrap gap-2 p-2 -ml-2'>
          <button
            className={`propertyPhasesButton ${
              transactionType.includes('all') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
            }   text-black text-xs w-20 h-8`}
            onClick={() => handleTransactionTypeClick('all')}
          >
            All
          </button> 
          <button
            className={`propertyPhasesButton ${
              transactionType.includes('COMPLETED') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
            } text-black text-xs w-20 h-8 `}
            onClick={() => handleTransactionTypeClick('COMPLETED')}
          >
            Completed
          </button>
          <button
            className={`propertyPhasesButton ${
              transactionType.includes('PENDING') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
            } text-black text-xs  w-20 h-8 `}
            onClick={() => handleTransactionTypeClick('PENDING')}
          >
            Pending
          </button>
          <button
          className={`propertyPhasesButton ${
            transactionType.includes('CANCEL') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
          } text-black text-xs w-20 h-8 `}
          onClick={() => handleTransactionTypeClick('CANCEL')}
        >
          Cancel
        </button>
        </div>
      </div>
    )}
    </div>
  </div>




<div className='flex flex-row justify-center'>
    <hr style={{ color: "rgba(217, 217, 217, 1)",width:"100%" }}></hr>
  </div>

  <div className=' text-black flex flex-col py-2 px-4 ' onClick={()=>setcityClicked(true)}>
  <div className='flex flex-row justify-between' onClick={toggleOptionsCity}>
  <div className='text-base font-medium text-start'>City</div>
  
  <div className='flex flex-col justify-center'>
   {isOpenOptionsCity ? <FaCaretRight /> : <FaCaretDown />}
  </div>
</div>

{(isOpenOptionsCity &&
  <div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
    {placeFilters.map(city => (
      <CityButton
        key={city.city_id}
        city={city}
        onClick={handleCityClick}
        selectedCities={selectedCities}
      />
    ))}
  </div>
  )}
</div>
<div className='flex flex-row justify-center'>
<hr style={{ color: "rgba(217, 217, 217, 1)",width:"100%" }}></hr>
</div>


<div className=' text-black flex flex-col py-2 px-4 ' onClick={()=>setBuilderClicked(true)}>
  <div className='flex flex-row justify-between' onClick={toggleOptionsBuilder}>
  <div className='text-base font-medium text-start'>Builder's name</div>
  
  <div className='flex flex-col justify-center'>
   {isOpenOptionsBuilder ? <FaCaretRight /> : <FaCaretDown />}
  </div>
</div>

{(isOpenOptionsBuilder &&
  <div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
    {builderFilter.map(builder => (
   <BuilderButton
   key={builder.builder_id}
   builder={builder}
   onClick={handleBuilderClick}
   selectedBuilders={selectedBuilders}
 />
  ))}
  </div>
  )}
</div>




<div className='flex flex-row justify-center'>
<hr style={{ color: "rgba(217, 217, 217, 1)",width:"100%" }}></hr>
</div>


<div className=' text-black flex flex-col py-2 px-4 '>
  <div className='flex flex-row justify-between' onClick={toggleOptionsPrice}>
  <div className='text-base font-medium text-start'>Price Range</div>
  
  <div className='flex flex-col justify-center'>
   {isOpenOptionsPrice ? <FaCaretRight /> : <FaCaretDown />}
  </div>
</div>

{(isOpenOptionsPrice &&
  <div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
  
  <div className='text-lg flex flex-col font-bold text-start  p-2 gap-3 -ml-2'>
      <div className=' text-black flex flex-row gap-4 ' >
        <PriceRangeButton name="Upto 50000" priceRange={priceRange} onClick={handlePriceRangeClick} />
     
     
     
     
      </div>
      <div className=' text-black flex flex-row gap-4'>
        
        <PriceRangeButton name="50,000-1,00,000" onClick={handlePriceRangeClick} priceRange={priceRange} />
      </div>
      <div className=' text-black flex flex-row gap-4'>
      
        <PriceRangeButton name="Above 1,00,000" onClick={handlePriceRangeClick} priceRange={priceRange} />
      </div>
      
     
    </div>







  </div>
  )}
</div>

<div className='flex flex-row justify-center'>
<hr style={{ color: "rgba(217, 217, 217, 1)",width:"100%" }}></hr>
</div>


<div className=' text-black flex flex-col py-2 px-4 '>

<div className='flex flex-row justify-between' onClick={toggleOptionsDates}>
  <div className='text-base font-medium text-start'>Select Date </div>
  
  <div className='flex flex-col justify-center'>
   {isOpenOptionsDate ? <FaCaretRight /> : <FaCaretDown />}
  </div>
</div>
{(isOpenOptionsDate && 
<>

<div className='flex mt-2 justify-between  shadow-md border border-lightgray p-2 rounded-md'>


  <div className='flex flex-col justify-start items-start '>

        
<label className='text-xs text-gray'>From</label>
<input type="date" 
className='px-1 rounded-md '
 value={fromDate.slice(0, 10)}
onChange={(e)=>setFromDate(getCurrentFormattedDate(new Date(e.target.value)))}
 />

</div>

<div className='flex flex-col justify-start items-start'>


<label className='text-xs text-gray'>To</label>
       <input type="date"
      className=' px-1 rounded-md '
     value={toDate.slice(0, 10)}
     onChange={(e)=>setToDate(getCurrentFormattedDate(new Date(e.target.value)))}
       
       />
</div>  

</div>
</>)}

</div>





 
  </div>
  <div className='flex flex-row absolute bottom-0 w-full justify-between py-2 px-4'>
  <div className='flex flex-col justify-center mt-2  text-[14px] font-normal belowBorders h-[44px] w-[150px] rounded-lg cursor-pointer' onClick={()=>setShowBuyingMobFilter(false)}>Close</div>
  <div className='flex flex-col justify-center mt-2  text-[14px] text-white font-normal belowBorders h-[44px] w-[150px] rounded-lg bg-secondary cursor-pointer' onClick={ApplyFilter}>Apply</div>
</div>
  </div>

  </>
  );
}



export default TransactionFilterMobile
