import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import './Index.css'
import { DefaultFilter } from '../../Utils/urls';

import { ApiBaseUrl } from '../../Utils/urls';
import { RxCross2 } from "react-icons/rx";
import { getBuyingFilter } from '../../Api/BuyingFilter';




function CityButton({ city, onClick, selectedCities }) {
  const isClicked = selectedCities.includes(city.city_name);

  const handleClick = () => {
    onClick(city.city_name);
  };

  return (
    <button
      className={`cityButton text-black text-sm h-7 pl-2 pr-2 ${isClicked ? 'clickedButton' : ''}`}
      onClick={handleClick}
    >
      {city.city_name}
    </button>
  );
}

  
function BuilderButton({ builder, onClick, selectedBuilders }) {
  if (!builder) {
    return null;
  }
  const isClicked = selectedBuilders.includes(builder.builder_name);
  const handleClick = () => {
    onClick(builder.builder_name);
  };

  return (
    <button
      className={`BuilderButton text-black text-sm h-7 pl-2 pr-2 ${isClicked ? 'clickedButton' : ''}`}
      onClick={handleClick}
    >
      {builder.builder_name}
    </button>
  );
}


  
  // function  PriceRangeButton({ name, onClick, priceRange }) {
  //     const isClicked = priceRange.includes(name);
    
  //     const handleClick = () => {
  //       onClick(name);
  //     };
    
  //     return (
  //       <div className="flex flex-row gap-4 w-full" onClick={handleClick}>
  //         <div className={`checkBox  text-black ${isClicked ? 'checked' : ''}`} />
  //         <input type='radio' name='price'
  //           className=" text-black text-sm font-normal"
  //         />
  //           {name}
          
         
          
  //       </div>
  //     );
  //   }


  function PriceRangeButton({ name, onClick, priceRange }) {
    const isClicked = priceRange[name];
  
    const handleClick = () => {
      onClick(name);
     // console.log(priceRange,isClicked)
    };

   // console.log(priceRange,isClicked)
  
    return (
      <div className="flex flex-row   text-sm font-normal items-center gap-2 w-full" onClick={handleClick}>
        <div className={`priceRangeRadio text-black ${isClicked ? 'priceRangeselected' : ''}`} />
        <input type='radio' name='price' className="text-black text-sm font-normal" style={{display:'none'}} />
        {name}
      </div>
    );
  }
  



  
    

    




function TransactionFilter(props) {
  const [userId, setUserId] = useState(null);
const accesstoken=localStorage.getItem("accestoken")

  // const [filters, setBuyingFilters] = useState({
  //   user_id: userId,
  //   filtersByString: {
  //     sale_type: null 
  // },
  //  filterByStatus:[],
 
  // purchased_date: {
  //     "from": "2023-03-12 00:00:00",
  //     "to": "2024-04-19 23:59:59"
  // }, 
  // filtersByPlaces: [],
 
  // filtersByBuilders: []
  // });

  //filters
 
  const [placeFilters, setPlaceFilters] = useState([]);
  const [builderFilter,setBuilderFilter]=useState([])
  const [saleTypes, setSaleTypes] = useState([]);
  const [transactionType,setTransactionType]=useState([])
  const [priceRange, setPriceRange] = useState({});


  const [selectedPlaces, setSelectedPlaces] = useState([]);
  const [selectedBuilders, setSelectedBuilders] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);


  const currentDate = new Date();
  const sixtyDaysAgo = new Date(currentDate);
  sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);
  sixtyDaysAgo.setHours(sixtyDaysAgo.getHours() + 5);
  sixtyDaysAgo.setMinutes(sixtyDaysAgo.getMinutes() + 30);
  const fromDateString = sixtyDaysAgo.toISOString().slice(0, 19).replace('T', ' ');
  
  currentDate.setHours(currentDate.getHours() + 5); 
  currentDate.setMinutes(currentDate.getMinutes() + 30); 
  const toDateString = currentDate.toISOString().slice(0, 19).replace('T', ' ');

// const [isBuyingLoading,setBuyingLoading]=useState(false)
//   const [BoughtResultData, setBuyingResultData] = useState(null);


const {handleApplyFilter,
  setBuyingFilters,
  boughtfilters,
  toDate,
  fromDate,
  setFromDate,
  setToDate,
  getCurrentFormattedDate

}=props
const [isLoggedin, setisLoggedin] = useState(false); 
const initialBookingFilter={
  user_id: userId,
  filtersByString: {
      sale_type: null 
  },
   filterByStatus:[],
  
  purchased_date: {
      "from": fromDate,
        "to": toDate
  }, 
  filtersByPrice:{token_price: null},

  filtersByPlaces: [],
 
  filtersByBuilders: []
 
}



useEffect(() => {
  const userIdFromLocalStorage = localStorage.getItem('user_id');
  const sessionLSFromLocalStorage = localStorage.getItem('sessionLS');
  
  if (userIdFromLocalStorage && sessionLSFromLocalStorage) {
    setisLoggedin(true);
    setUserId(userIdFromLocalStorage);
  }
}, []);


const fetchBuyingFilter = async () => {

  getBuyingFilter( 
    accesstoken,
    initialBookingFilter,
    setBuilderFilter,
    setPlaceFilters,
   
  )

};

// let mql = window.matchMedia("(max-width: 640px)");
// console.log(mql)

useEffect(()=>{
  fetchBuyingFilter()
},[userId])



//salestype filters
const handleSaleTypeClick = (saleType) => {
  let updatedSaleTypes;
  if (saleTypes.includes(saleType)) {
    updatedSaleTypes = saleTypes.filter((type) => type !== saleType);
  } else {
    updatedSaleTypes = [...saleTypes, saleType];
  }

  setSaleTypes(updatedSaleTypes);


  // Update property_filters with the new sale types
  let updatedPropertySaleTypes;
  if (updatedSaleTypes.length === 0) {
    updatedPropertySaleTypes = null;
  } else if (updatedSaleTypes.includes('all')) {
    updatedPropertySaleTypes = ['Public Sale', 'Resale','Presale'];
  } else {
    updatedPropertySaleTypes = updatedSaleTypes;
  }

  setBuyingFilters((prevFilters) => ({
    ...prevFilters,
    filtersByString: {
      ...prevFilters.filtersByString,
      sale_type: updatedPropertySaleTypes,
    },
  }));

  
};

//transaction type
const handleTransactionTypeClick = (transType) => {
  let updatedTransactionTypes;
  if (transactionType.includes(transType)) {
    updatedTransactionTypes = transactionType.filter((type) => type !== transType);
  } else {
    updatedTransactionTypes = [...transactionType, transType];
  }

  setTransactionType(updatedTransactionTypes);


  // Update property_filters with the new sale types
  let updatedPropTransactionTypes;
  if (updatedTransactionTypes.length === 0) {
    updatedPropTransactionTypes = [];
  } else if (updatedTransactionTypes.includes('all')) {
    updatedPropTransactionTypes = ["COMPLETED","PENDING","CANCEL"];
  } else {
    updatedPropTransactionTypes = updatedTransactionTypes;
  }

  setBuyingFilters((prevFilters) => ({
    ...prevFilters,
    filterByStatus:updatedPropTransactionTypes
  }));

  
};

//builder filter 
const handleBuilderClick = (builderName) => {
  let updatedBuilders;

  if (selectedBuilders.includes(builderName)) {
      updatedBuilders = selectedBuilders.filter((builder) => builder !== builderName);
  } else {
      updatedBuilders = [...selectedBuilders, builderName];
  }

  setSelectedBuilders(updatedBuilders);

  const updatedFiltersByBuilders = updatedBuilders.map((builderName) => {
      const selectedBuilder = builderFilter.find(builder => builder.builder_name === builderName);
      return selectedBuilder ? selectedBuilder.builder_id : null;
  }).filter(id => id !== null);
  const updatedBuyingPropertyFilters = { ...boughtfilters, filtersByBuilders: updatedFiltersByBuilders };
  setBuyingFilters(updatedBuyingPropertyFilters);


  //setIsFilterActive(true);
};

// city select
const handleCityClick = (cityName) => {
  let updatedCities;
  if (selectedCities.includes(cityName)) {
      updatedCities = selectedCities.filter((city) => city !== cityName);
  } else {
      updatedCities = [...selectedCities, cityName];
  }

  setSelectedCities(updatedCities);
  const updatedFiltersByPlaces = updatedCities.map((cityName) => {
      const selectedCity = placeFilters.find(city => city.city_name === cityName);
      return { city_id: selectedCity.city_id, locations: []  };
  });


  const updatedBookedPropertyFilters = { ...boughtfilters, filtersByPlaces: updatedFiltersByPlaces };
  setBuyingFilters(updatedBookedPropertyFilters);

  //setIsFilterActive(true);
};
//price select
// const handlePriceRangeClick = (price) => {
//   let updatedPriceRange;
//   if (priceRange.includes(price)) {
//       updatedPriceRange = priceRange.filter((range) => range !== price);
//   } else {
//       updatedPriceRange = [...priceRange, price];
//   }

//   setPriceRange(updatedPriceRange);
//   //setIsFilterActive(true);

//   // Update property_filters with the new price range
//   let filtersByPrice = {};

//   if (updatedPriceRange.includes("Upto 50000")) {
//       filtersByPrice = { from: "0", to: "50000" };
//   } else if (updatedPriceRange.includes("50,000-1,00,000")) {
//       filtersByPrice = { from: "50000", to: "100000" };
//   } else if (updatedPriceRange.includes("Above 1,00,000")) {
//       filtersByPrice = { from: "100000", to: "10000000" };
//   } else {
//       // If no price range is selected, set to null
//       filtersByPrice = null;
//   }

//   setBuyingFilters(prevFilters => ({
//       ...prevFilters,
//       filtersByPrice: { token_price: filtersByPrice }
//   }));
// };

const handlePriceRangeClick = (name) => {
  // Update the state with the new price range name
  setPriceRange(prevPriceRange => {
    const updatedPriceRange = { ...prevPriceRange };
    Object.keys(updatedPriceRange).forEach(key => {
      updatedPriceRange[key] = false; // Deselect all other options
    });
    updatedPriceRange[name] = true; // Select the clicked option
    return updatedPriceRange;
  });
  // Update property_filters with the new price range
  let filtersByPrice = {};

  if (name === "Upto 50000") {
    filtersByPrice = { from: "0", to: "50000" };
  } else if (name === "50,000-1,00,000") {
    filtersByPrice = { from: "50000", to: "100000" };
  } else if (name === "Above 1,00,000") {
    filtersByPrice = { from: "100000", to: "10000000" };
  } else {
    // If no price range is selected, set to null
    filtersByPrice = null;
  }

  setBuyingFilters((prevFilters) => ({
    ...prevFilters,
    filtersByPrice: { token_price: filtersByPrice },
  }));
};



useEffect(()=>{
  if(userId)
  {
    handleApplyFilter()
  }

},[saleTypes,selectedBuilders,transactionType,selectedCities,priceRange])


//clear salestype function
const handleSaleTypeClearClick = () => {
  setSaleTypes([]);
  setBuyingFilters((prevFilters) => ({
    ...prevFilters,
    filtersByString: {
      ...prevFilters.filtersByString,
      sale_type: null,
    },
  }));
}

//clear transacrion Type 
const handleTransactionTypeClearClick=()=>{
  setTransactionType([])
  setBuyingFilters((prevFilters) => ({
    ...prevFilters,
    filterByStatus:[]
  }));
}

const handleClearCityClick=()=>{
  setSelectedCities([])
  setBuyingFilters((prevFilters) => ({
    ...prevFilters,
    filtersByPlaces:[]
  }));
}


const handlebuilderClearClick=()=>{
  setSelectedBuilders([]);
  setBuyingFilters((prevFilters) => ({
    ...prevFilters,
    filtersByBuilders:[]
  }));
}
const handlePriceClear=()=>{
  setPriceRange({})
  setBuyingFilters((prevFilters) => ({
    ...prevFilters,
    filtersByPrice: { token_price: null }
  }));
}

const handleAllClearClick = () => {
 
  setSaleTypes([]);
  setSelectedCities([]);
  setSelectedBuilders([]);
  setTransactionType([])
  setPriceRange({});
  // setStartDate('');
  // setEndDate('');
  //setIsFilterActive(false);
  //setIsClearButtonActive(false);

  setBuyingFilters(initialBookingFilter)

};



// const handleApplyFilter=()=>{
//   setBuyingLoading(true)
//   fetch(`${ApiBaseUrl}/fr-user/get-user-purchase-transactions`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       'Authorization': accesstoken 
//     },
//     body: JSON.stringify({
//       ...filters,
//       user_id: userId
     
//     }),
//   })
//   .then(response => {
//     if (!response.ok) {
//       throw new Error('Failed to fetch result');
//     }
//     return response.json();
//   })
//   .then(res => {
   
//     setBuyingLoading(true)
//     setBuyingResultData(res.data);
//   })
//   .catch(error => {
//     console.error('Error fetching result:', error);
//   });
// }

// if(!transactionType.length && !saleTypes.length && !selectedCities.length && !selectedBuilders.length  && Object.keys(priceRange).length === 0)
// {
//   setClearAll(true)
// }


  const [leftPaneHeight, setLeftPaneHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setLeftPaneHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>

    <div className='px-4 py-4 '>
    <div className='text-black flex flex-row justify-between mt-2   '>
    <div className='text-black text-[16px] font-bold'>Filter </div>
    <div className='flex flex-row gap-2 pr-2' >
    
    {/* 
    <button
    className={`text-xs w-16 font-medium h-5 flex flex-row justify-center ${
      isFilterActive ? 'ButtonCollect' : 'ButtonBeforeCollect'
    }`}
    disabled={!isFilterActive}
  >
  <div className='flex flex-col justify-center'>Apply</div>
  </button>
  */}


  <button
  
  className={`clearButton text-xs text-start font-normal -mr-3 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer ${
    // Check if all filters are cleared
    !transactionType.length && !saleTypes.length && !selectedCities.length && !selectedBuilders.length  && Object.keys(priceRange).length === 0 ? 'ButtonBeforeCollect' : 'ButtonCollect'
  }`}

  onClick={handleAllClearClick}
      >
        Clear
      </button>
<div className='ml-3' onClick={()=>props.setIsOpenMob(false)}><RxCross2 /></div>
</div> 


  </div>

    <div className='flex flex-row justify-center enquiryParent mt-1'>
      <hr style={{ color: 'rgba(217, 217, 217, 1)', width: '100%' }}></hr>
    </div>

 {/* sales type */}
  <div className=' text-black flex flex-col pt-4'>
  <div className='flex flex-row justify-between'>
  <div className='text-[16px] font-medium text-start'>Sales Type</div>
  <div className='flex flex-col justify-center'>
    <div className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer'
  onClick={handleSaleTypeClearClick}
    >Clear</div>
  </div>

  </div>

    <div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
      <button
        className={`propertyPhasesButton  text-black text-sm w-14 h-7 ${
          saleTypes.includes('all') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSaleTypeClick('all')}
      >
        All
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-20 h-7  whitespace-nowrap ${
          saleTypes.includes('Presale') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSaleTypeClick('Presale')}
      >
        Presale
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-24 h-7 whitespace-nowrap ${
          saleTypes.includes('Public Sale') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSaleTypeClick('Public Sale')}
      >
        Public Sale
      </button>
      <button
        className={`propertyPhasesButton  text-black text-sm w-20 h-7 ${
          saleTypes.includes('Resale') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={() => handleSaleTypeClick('Resale')}
      >
        Resale
      </button>
    </div>

  </div>

{/* transaction type */}


<div className=' text-black flex flex-col pt-4'>
  <div className='flex flex-row justify-between'>
  <div className='text-[16px] font-medium text-start'>Transaction Type</div>
  <div className='flex flex-col justify-center'>
    <div className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer'
   onClick={handleTransactionTypeClearClick}
    >Clear</div>
  </div>

  </div>
    <div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
      <button
        className={`propertyPhasesButton  text-black text-sm w-14 h-7 ${
          transactionType.includes('all') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={()=>handleTransactionTypeClick('all')}
      >
        All
      </button>
      <button
        className={`propertyPhasesButton  text-black text-xs w-20 h-7  whitespace-nowrap ${
          transactionType.includes('COMPLETED') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
    onClick={()=>handleTransactionTypeClick('COMPLETED')}
      >
        Completed
      </button>
      <button
        className={`propertyPhasesButton  text-black text-xs w-24 h-7 whitespace-nowrap ${
          transactionType.includes('PENDING') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={()=>handleTransactionTypeClick('PENDING')}
      >
        Pending
      </button>
      <button
        className={`propertyPhasesButton  text-black text-xs w-20 h-7 ${
          transactionType.includes('CANCEL') ? 'propertyCollectButton' : 'propertyCollectButtonBefore'
        }`}
        onClick={()=>handleTransactionTypeClick('CANCEL')}
      >
        Cancel
      </button>
    </div>
  </div>

{/* city */}

<div className=' text-black flex flex-col pt-4'>
  <div className='flex flex-row justify-between'>
    <div className='text-[16px] font-medium text-start'>City</div>
    <div className='flex flex-col justify-center'>
      <div
        className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer'
        onClick={handleClearCityClick}
      >
        Clear
      </div>
    </div>
  </div>
  <div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
    {placeFilters.map(city => (
      <CityButton
        key={city.city_id}
        city={city}
        onClick={handleCityClick}
        selectedCities={selectedCities}
      />
    ))}
  </div>
</div>

{/* builder */}

<div className=' text-black flex flex-col pt-4'>
  <div className='flex flex-row justify-between'>
  <div className='text-[16px] font-medium text-start'>Builder's name</div>
  <div className='flex flex-col justify-center'>
    <div className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg py-0.5 px-2 h-5 cursor-pointer'
   onClick={handlebuilderClearClick}
    >Clear</div>
  </div>

  </div>

  
    <div className=' flex flex-row flex-wrap gap-2 p-2 -ml-2'>
    {builderFilter.map(builder => (
    <BuilderButton
      key={builder.builder_id}
      builder={builder}
      onClick={handleBuilderClick}
      selectedBuilders={selectedBuilders}
    />
  ))}
   
    </div>
  </div>

  
{/* price range */}
<div className=' text-black pt-4 flex flex-col mb-12'>
  <div className='flex flex-row justify-between'>
  <div className='text-[16px] font-medium text-start'>Price Range</div>
  <div className='flex flex-col justify-center'>
    <div className='clearButton text-xs text-start font-normal -mr-1 flex flex-col justify-center rounded-lg px-2 h-5 cursor-pointer'
   onClick={handlePriceClear}
   >Clear</div>
  </div>

  </div>
    <div className='text-lg font-bold text-start flex flex-row p-2 gap-3 -ml-2'>
      <div className=' text-black flex flex-row gap-4 ' >
        <PriceRangeButton name="Upto 50000" priceRange={priceRange} onClick={handlePriceRangeClick} />
     
     
     
     
      </div>
      <div className=' text-black flex flex-row gap-4'>
        
        <PriceRangeButton name="50,000-1,00,000" onClick={handlePriceRangeClick} priceRange={priceRange} />
      </div>
      <div className=' text-black flex flex-row gap-4'>
      
        <PriceRangeButton name="Above 1,00,000" onClick={handlePriceRangeClick} priceRange={priceRange} />
      </div>
      
     
    </div>
  </div>

  <div className='text-[16px] font-medium text-start'>Select Dates</div>
<div className='flex justify-between w-[70%] shadow-md border border-lightgray p-2 rounded-md'>


  <div className='flex flex-col justify-start items-start '>

        
<label className='text-xs text-gray'>From</label>
<input type="date" 
className='px-1 rounded-md '
 value={fromDate.slice(0, 10)}
 onChange={(e)=>setFromDate(getCurrentFormattedDate(new Date(e.target.value)))}
 />

</div>

<div className='flex flex-col justify-start items-start'>


<label className='text-xs text-gray'>To</label>
       <input type="date"
      className=' px-1 rounded-md '
     value={toDate.slice(0, 10)}
      onChange={(e)=>setToDate(getCurrentFormattedDate(new Date(e.target.value)))}
       
       />
</div>  

</div>


  </div>

  </>
  );
}



export default TransactionFilter
